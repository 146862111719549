<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="search"
                  :loading="isLoading"
                  :items="sites"
                  item-text="full_name"
                  data-cy="site_id"
                  item-value="id"
                  class="mt-6 required"
                  hide-no-data
                  label="Start typing to search for a site"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Alternative Name"
                  name="alternative_name"
                  required
                  v-model="siteName"
                >
                  <v-tooltip slot="append" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" style="cursor: default">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    A custom Site Name as defined by the Sponsor.
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Alternative Site Identifier"
                  name="alternative_identifier"
                  required
                  v-model="siteIdentifier"
                >
                  <v-tooltip slot="append" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" style="cursor: default">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    A custom Site Identifer as defined by the Sponsor.
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Investigator Email"
                  required
                  v-model="investigatorEmail"
                  name="investigator_email"
                >
                  <v-tooltip slot="append" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" style="cursor: default">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Group Emails are acceptable.
                  </v-tooltip>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveSite"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "studyId", "action", "selectedStudySite"],
  mixins: [hasuraService],
  data() {
    return {
      investigatorEmail: "",
      isLoading: false,
      formTitle: "Loading...",
      siteName: "",
      siteIdentifier: "",
      site: null,
      sites: [],
      search: null,
      select: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async studyId(newVal) {
      if (newVal !== null) {
        const study = this.$store.getters.study(this.studyId);

        if (study == null) {
          this.study = await this.fetchStudyData(this.studyId);
        } else {
          this.study = study;
        }
      }
    },
    search(val) {
      const pickedSite = this.sites.find((site) => site.id === val);
      if (pickedSite) {
        this.site = pickedSite;
        // Don't override user inputs, but set defaults
        this.siteName = this.siteName || pickedSite.name || "";
        this.siteIdentifier =
          this.siteIdentifier || pickedSite.identifier || "";
      }
    },
  },
  methods: {
    async editSite() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      console.log("editSite");
      console.log(this.site);

      // attachSiteToStudy should work, as it handles both add and update
      const siteData = await this.attachSiteToStudy(
        this.studyId,
        this.site.id,
        this.siteName,
        this.siteIdentifier,
        this.investigatorEmail
      );

      this.$emit("callback", siteData);
      this.show = false;
      return true;
    },

    async addSite() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      console.log("addSite");
      console.log(this.site);

      // return if this study already has this site
      console.log(this.study.study_sites);
      console.log(this.site);
      if (this.study.study_sites.some((site) => site.id === this.site.id)) {
        this.isLoading = false;
        console.log("Site already exists");
        return false;
      }

      const siteData = await this.attachSiteToStudy(
        this.studyId,
        this.site.id,
        this.siteName,
        this.siteIdentifier,
        this.investigatorEmail
      );

      this.$emit("callback", siteData);
      this.show = false;
      return true;
    },

    async saveSite() {
      // validate investigatorEmail format
      if (
        // I hate JS for this
        !(
          this.investigatorEmail === "" ||
          this.investigatorEmail === null ||
          this.investigatorEmail === undefined
        )
      ) {
        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.investigatorEmail)) {
          this.$store.commit("setSnack", {
            text: "Invalid email format for Investigator Email. Please enter a valid email address.",
            color: "error",
          });
          this.isLoading = false;
          return false;
        }
      }

      if (this.search == "" || this.search == null) {
        this.$store.commit("setSnack", {
          text: "Please select a site",
          color: "error",
        });
        this.isLoading = false;
        return false;
      }

      if (this.action == "edit") {
        await this.editSite();
      } else {
        await this.addSite();
      }
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id);
    },
    async listSites() {
      console.log("listSites");
      const storedSites = this.$store.getters.sites;
      if (Object.keys(storedSites).length > 0) {
        this.sites = Object.values(storedSites);
      } else {
        this.sites = await this.listSitesRequest();
        this.$store.commit("mergeSites", { sites: this.sites, reload: true });
      }

      // add full_name to sites, where full name = {id} - {name}
      this.sites = JSON.parse(JSON.stringify(this.sites));
      this.sites = this.sites.map((site) => {
        site.full_name = `${site.id} - ${site.name}`;
        return site;
      });
    },
  },
  mounted() {
    if (this.action == "edit") {
      this.listSites().then(() => {
        this.search = this.selectedStudySite.site_id;
        this.siteName = this.selectedStudySite.site_name || "";
        this.siteIdentifier = this.selectedStudySite.site_identifier || "";
        this.investigatorEmail =
          this.selectedStudySite.investigator_email || "";
      });
    } else {
      this.listSites();
    }
    this.formTitle =
      this.action == "edit" ? "Edit Study Site" : "Add Study Site";
  },
};
</script>
