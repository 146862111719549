import LoginView from '../views/LoginView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';

export const authRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginView,
        meta: {
            hideAppbar: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPasswordView,
        meta: {
            hideAppbar: true,
        },
    },
    {
        path: '/reset-password/:usertype/:token',
        name: 'ResetPassword',
        component: ResetPasswordView,
        meta: {
            hideAppbar: true,
        },
    },
]