<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="450">
      <v-card v-if="visitAssessmentId !== null">
        <v-card-title class="text-h5">
          <span v-if="!archive">Archive</span>
          <span v-else>Unarchive</span> &nbsp;visit assessment?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Cancel </v-btn>
          <v-btn color="error" @click="archiveAssessment()">
            <span v-if="!archive">Archive</span>
            <span v-else>Unarchive</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["visitAssessmentId", "value", "archive"],
  mixins: [hasuraService],
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async archiveAssessment() {
      const result = await this.archiveVisitAssessmentById(
        this.visitAssessmentId,
        !this.archive
      );
      this.show = false;
      this.$emit("callback", this.visitAssessmentId);
      return result;
    },
  },
};
</script>
