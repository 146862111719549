<template>
  <v-container>
    <v-row class="my-2">
      <v-col align-self="end">
        <h1 class="display-6 font-weight-regular">Users</h1>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Users"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>

          <v-btn color="primary" @click="newUser">New User</v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="filtered_data"
      :items-per-page="-1"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.site="{ item }">
        <span v-if="item.site">{{ item.site.name }}</span>
        <span v-else>n/a</span>
      </template>
      <template v-slot:item.user_studies="{ item }">
        <v-btn
          class="mr-4"
          icon
          small
          :to="{ name: 'UserStudies', params: { id: item.id } }"
        >
          <v-icon title="Studies"> mdi-microscope </v-icon>
          <v-badge
            v-if="item.user_studies.length > 0"
            class="text-overline badge-size-small"
            color="secondary"
            :content="item.user_studies.length"
            offset-y="-2"
          />
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2" icon small @click="editUser(item)">
          <v-icon dark> mdi-pencil-outline </v-icon>
        </v-btn>
        <v-btn class="mx-2" icon small @click="archiveUser(item)">
          <v-icon dark v-if="!toggle_archive"> mdi-delete-outline </v-icon>
          <v-icon dark v-else> mdi-delete-restore </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <user-form
      v-if="showUserForm"
      v-model="showUserForm"
      v-bind:user="formUser"
    ></user-form>
    <v-dialog v-model="showArchiveUserDialog" persistent max-width="400">
      <archive-form
        v-if="showArchiveUserDialog"
        :itemId="archive_id"
        :description="archive_description"
        :archive="toggle_archive"
        tableName="dia_app_user"
        prettyName="User"
        @close="showArchiveUserDialog = false"
      ></archive-form>
    </v-dialog>
  </v-container>
</template>

<script>
import UserForm from "@/components/users/UserForm.vue";
import ArchiveForm from "@/components/Archive.vue";
import gql from "graphql-tag";

const baseUsers = `
    dia_app_user(order_by: {id: asc}) {
      id
      name
      email
      type
      created_at
      updated_at
      archived
      user_studies(where: { archived: { _eq: false } }) {
        study_id
        user_id
        study {
          id
          name
          description
          user_study_sites {
            site_id
          }
        }
      }
    }
`;

const SUBSCRIPTION_USERS = gql`
  subscription USERS {
    ${baseUsers}
  }
`;

const GET_USERS = gql`
  query USERS {
    ${baseUsers}
  }
`;

export default {
  name: "UsersView",
  components: {
    "user-form": UserForm,
    "archive-form": ArchiveForm,
  },

  data() {
    return {
      loading: true,

      toggle_archive: false,
      archive_id: null,
      archive_description: "",
      showArchiveUserDialog: false,

      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "type" },
        { text: "Studies", value: "user_studies" },
        { text: "Actions", value: "actions", width: "120px" },
      ],
      dia_app_user: [],
      showUserForm: false,
      formUser: null,
    };
  },
  apollo: {
    dia_app_user: {
      query: GET_USERS,
      subscribeToMore: {
        document: SUBSCRIPTION_USERS,
        updateQuery: (previousResult, { subscriptionData }) => {
          return subscriptionData.data;
        },
      },
    },
  },
  computed: {
    filtered_data() {
      return this.dia_app_user.filter((item) => {
        return item.archived === this.toggle_archive;
      });
    },
  },
  methods: {
    async newUser() {
      this.formUser = null;
      this.showUserForm = true;
    },
    async editUser(user) {
      this.formUser = user;
      this.showUserForm = true;
    },
    async archiveUser(user) {
      this.archive_id = user.id;
      this.archive_description = user.name;
      this.showArchiveUserDialog = true;
    },
  },
  async mounted() {
    this.loading = false;
  },
};
</script>

<style>
.user-filter-form {
  width: 100%;
}
</style>
