<template>
  <v-container bg fill-height grid-list-md text-xs-center>
    <v-layout row wrap align-center>
      <v-flex align-center>
        <v-card class="pa-8 ma-auto" max-width="400">
          <v-form ref="form" v-model="valid" @keyup.enter="submit">
            <v-row no-gutters class="mb-2">
              <p class="text-h6 font-weight-regular">Forgot Password</p>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-text-field v-model="email" label="Email" type="email" required outlined clearable
                  :rules="emailRules"></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="pr-0">
              <v-row justify="end" class="ma-0">
                <v-btn text href="/login" class="secondary--text mr-4">Sign In</v-btn>
                <v-btn color="primary" :disabled="!valid" @click="validateAndSubmit" :loading="isLoading">Submit</v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>

    <v-alert type="error" class="fp-alert" v-model="showError">
      <v-row>
        <v-col class="grow">
          {{ errorMessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="onErrorActionPressed"><v-icon>mdi-email</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert type="success" class="fp-alert" v-model="showSuccess">
      <v-row>
        <v-col class="grow">
          {{ successMessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="onSuccessActionPressed">OK</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import hasuraService from '@/services/hasura.service.js';

export default {
  name: 'ForgotPassowrd',
  mixins: [hasuraService],
  components: {},

  data() {
    return {
      valid: false,
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      isLoading: false,
      showError: false,
      errorMessage: 'Server error. Please, contact support.',
      successMessage: 'We\'ve sent you an email to your address.',
      showSuccess: false,
    };
  },

  methods: {
    onError(message) {
      if (message) {
        this.errorMessage = `${message}. Please, contact support.`;
      }
      this.showError = true;
      this.showSuccess = false;
    },
    onSuccess() {
      this.successMessage = 'We\'ve sent you an email to your address.';
      this.showSuccess = true;
      this.showError = false;
      this.isLoading = false;
    },
    onSuccessActionPressed() {
      this.showSuccess = false;
    },
    onErrorActionPressed() {
      this.showError = false;
    },
    /// I could not find a way to mock $refs in vitest so I had to move this logic to a separate method
    async validateAndSubmit() {
      const { form } = this.$refs;
      form.validate();

      if (this.valid) {
        this.submit();
      }
    },
    async submit() {
      this.isLoading = true;
      try {
        const result = await this.passwordResetStepOne(this.email);
        if (result.errors && result.errors.length > 0 && result.errors[0].message) {
          this.onError(result.errors[0].message);
        }

        if (result.result) {
          this.onSuccess();
          return true;
        }

        this.onError(result.reason);
      } catch (error) {
        this.onError(error);
      }

      this.isLoading = false;
      return false;
    },
  },
};
</script>

<style>
.v-alert.fp-alert {
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
</style>
