import { ApolloClient } from "apollo-client";
import VueApollo from "vue-apollo";
import Vue, { h } from "vue";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { HttpLink, split } from "@apollo/client/core";
import { getMainDefinition } from "@apollo/client/utilities";
import { InMemoryCache } from "@apollo/client/cache";

Vue.use(VueApollo);

// get uris from env variables
const uri = process.env.VUE_APP_URI;

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_URI,
  headers: {
    "content-type": "application/json",
    // 'x-hasura-admin-secret': prodSecret,
    ...(localStorage.getItem("token") && {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: uri.replace("http", "ws"),
    connectionParams: () => ({
      headers: {
        Authorization: localStorage.getItem("token")
          ? `Bearer ${localStorage.getItem("token")}`
          : null,
      },
    }),
  })
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export default new VueApollo({
  clients: {
    uatClient: apolloClient,
    prodClient: apolloClient,
  },
  defaultClient: apolloClient,
});
