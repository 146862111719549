<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ label }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  name="name"
                  required
                  v-model="name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Address"
                  name="address"
                  required
                  v-model="address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="City"
                  name="city"
                  required
                  v-model="city"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="State"
                  name="state"
                  required
                  v-model="state"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Zip"
                  required
                  v-model="zip"
                  name="zip"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="updateOrCreateSite"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "NewStudySite",
  props: ["value", "studyId", "site"],
  mixins: [hasuraService],
  data: () => ({
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    label: "New Site",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    site: {
      handler() {
        if (this.site) {
          this.name = this.site.name;
          this.address = this.site.address;
          this.city = this.site.city;
          this.state = this.site.state;
          this.zip = this.site.zip;
          this.label = "Edit Site";
        } else {
          this.name = "";
          this.address = "";
          this.city = "";
          this.state = "";
          this.zip = "";
          this.label = "New Site";
        }
      },
      immediate: true,
    },
  },
  methods: {
    async updateOrCreateSite() {
      let siteData = null;
      if (this.site) {
        siteData = await this.updateSiteRequest({
          id: this.site.id,
          name: this.name,
          address: this.address,
          city: this.city,
          state: this.state,
          zip: this.zip,
          studyId: this.studyId,
        });
      } else {
        siteData = await this.createSiteRequest({
          name: this.name,
          address: this.address,
          city: this.city,
          state: this.state,
          zip: this.zip,
          studyId: this.studyId,
        });
      }

      this.$emit("callback", siteData);
      this.show = false;
    },
  },
};
</script>
