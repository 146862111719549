<template>
  <v-container>
    <v-row class="my-2">
      <v-col align-self="end">
        <h1 class="display-6 font-weight-regular">Visits</h1>
        <v-breadcrumbs :items="breadcrumbs" class="pl-0 pt-2 pb-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Visits"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>

          <v-btn
            class="mr-5"
            v-if="studyId !== null"
            :to="{ name: 'VisitsSchedule', params: { id: studyId } }"
          >
            View Schedule
          </v-btn>
          <v-btn
            color="primary"
            @click="newVisit"
            :disabled="$store.state.app_locked"
          >
            New Visit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      sort-by="sequence"
      :sort-desc="sortDesc"
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:body="props">
        <draggable :list="props.items" tag="tbody" @end="saveSequense">
          <tr
            v-for="(item, index) in props.items"
            :key="index"
            class="drag-row"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.time_delay }} {{ item.time_unit }}</td>
            <td>{{ item.positive_slack_window }}</td>
            <td>{{ item.negative_slack_window }}</td>
            <td>{{ item.sequence }}</td>

            <td>
              <v-btn
                class="mr-4"
                icon
                small
                :to="{
                  name: 'VisitAssessments',
                  params: { id: item.study_id, visitId: item.id },
                }"
              >
                <v-icon dark title="Visit Assessments">
                  mdi-order-bool-ascending-variant
                </v-icon>
                <v-badge
                  v-if="item.interval_assessments.length > 0"
                  class="text-overline badge-size-small"
                  color="secondary"
                  :content="item.interval_assessments.length"
                  offset-y="-2"
                />
              </v-btn>
            </td>

            <td>
              <v-btn
                class="mr-2"
                icon
                small
                @click="editStudyVisit(item.id)"
                :disabled="$store.state.app_locked"
              >
                <v-icon dark> mdi-pencil-outline </v-icon>
              </v-btn>
              <v-btn
                icon
                small
                @click="archiveStudyVisit(item.id)"
                :disabled="$store.state.app_locked"
              >
                <v-icon v-if="toggle_archive" dark> mdi-delete-restore </v-icon>
                <v-icon v-else dark> mdi-delete-outline </v-icon>
              </v-btn>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <study-visit-form
      v-model="showStudyVisitForm"
      v-bind="{ studyId, visitId: editVisitId }"
      @callback="studyVisitCallback"
      @cancel="editVisitId = null"
    ></study-visit-form>
    <archive-study-visit-dialog
      v-model="showArchiveStudyVisitDialog"
      v-bind:visitId="archiveStudyVisitId"
      v-bind:archive="toggle_archive"
      @callback="archiveStudyVisitCallback"
    ></archive-study-visit-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import draggable from "vuedraggable";
import hasuraService from "@/services/hasura.service.js";
import StudyVisitForm from "@/components/studies/StudyVisitForm.vue";
import ArchiveStudyVisitDialog from "@/components/studies/ArchiveStudyVisitDialog.vue";

export default {
  name: "StudyVisits",
  mixins: [hasuraService],
  components: {
    "study-visit-form": StudyVisitForm,
    "archive-study-visit-dialog": ArchiveStudyVisitDialog,
    draggable,
  },

  data() {
    return {
      loading: true,
      toggle_archive: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Time Delay", value: "time_delay" },
        { text: "Pos. Slack", value: "positive_slack_window" },
        { text: "Neg. Slack", value: "negative_slack_window" },
        { text: "Sequence", value: "sequence" },
        { text: "Assessments", value: "interval_assessments" },
        { text: "Actions", value: "actions", width: "120px" },
      ],
      breadcrumbs: [
        {
          text: "Studies",
          disabled: false,
          to: { name: "Studies" },
          exact: true,
        },
        {
          text: "Study name",
          disabled: true,
        },
      ],
      sortBy: "id",
      sortDesc: false,
      data: [],
      showStudyVisitForm: false,
      editVisitId: null,
      showArchiveStudyVisitDialog: false,
      archiveStudyVisitId: null,
      studyId: null,
    };
  },

  watch: {
    toggle_archive() {
      this.loading = true;
      this.data = [];
      this.listVisits(this.$route.params.id, this.toggle_archive, false).then(
        (studyVisits) => {
          this.data = studyVisits;
          this.loading = false;
        }
      );
    },
  },

  methods: {
    saveSequense(action) {
      const { oldIndex, newIndex } = action;
      let local_data = JSON.parse(JSON.stringify(this.data));
      const item = local_data.splice(oldIndex, 1)[0];
      local_data.splice(newIndex, 0, item);
      local_data.forEach((element, index) => {
        element.sequence = index + 1;

        this.updateStudyVisitSequense({
          id: element.id,
          sequence: element.sequence,
        });
      });
      this.data = local_data;
    },
    newVisit() {
      this.showStudyVisitForm = true;
    },
    editStudyVisit(id) {
      this.editVisitId = id;
      this.showStudyVisitForm = true;
    },
    studyVisitCallback(studyVisitData) {
      const index = this.data.findIndex(
        (element) => element.id === studyVisitData.id
      );
      if (index === -1) {
        this.data.push(studyVisitData);
      } else {
        this.data[index].name = studyVisitData.name;
        this.data[index].sequence = studyVisitData.sequence;
        this.data[index].time_delay = studyVisitData.time_delay;
        this.data[index].time_unit = studyVisitData.time_unit;
        this.data[index].positive_slack_window =
          studyVisitData.positive_slack_window;
        this.data[index].negative_slack_window =
          studyVisitData.negative_slack_window;
      }

      this.$store.commit("updateStudyVisit", studyVisitData);
      console.log("Setting editVisitId to null");
      this.editVisitId = null;
    },
    archiveStudyVisit(visitId) {
      this.archiveStudyVisitId = visitId;
      this.showArchiveStudyVisitDialog = true;
    },
    archiveStudyVisitCallback(visitId) {
      this.data = this.data.filter((element) => element.id !== visitId);
      this.$store.commit("removeStudyVisitFromStore", visitId);
    },
    async setBreadcrumbs() {
      const study = this.$store.getters.study(this.$route.params.id);

      if (study === null) {
        this.study = await this.fetchStudy(this.$route.params.id);
      } else {
        this.study = study;
      }

      if (this.study && this.study.name) {
        this.studyId = this.study.id;

        this.breadcrumbs = [
          {
            text: "Studies",
            disabled: false,
            to: { name: "Studies" },
            exact: true,
          },
          {
            text: this.study.name,
            disabled: true,
          },
        ];
      }
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id);
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    const studyVisits = await this.listVisits(this.$route.params.id);
    this.data = JSON.parse(
      JSON.stringify(studyVisits.filter((visit) => visit.archived === false))
    );

    this.loading = false;
  },
};
</script>

<style scoped>
.drag-row {
  cursor: move;
}
</style>
