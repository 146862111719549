<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  required
                  v-model="visitName"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Time delay"
                  required
                  type="number"
                  v-model="visitTimeDelay"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="visitTimeUnit"
                  :items="visitTimeUnitSelectOptions"
                  label="Time unit"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Positive slack window"
                  required
                  type="number"
                  v-model="visitPosSlackWindow"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Negative slack window"
                  required
                  type="number"
                  v-model="visitNegSlackWindow"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="visitUnscheduled"
                  label="Unscheduled?"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="cancel"> Cancel </v-btn>
          <v-btn color="primary" @click="updateOrCreateVisit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "StudyVisitForm",
  props: ["value", "studyId", "visitId"],
  mixins: [hasuraService],
  data: () => ({
    formTitle: "New Visit",

    visitName: "",
    visitSequence: 1000,
    visitTimeDelay: null,
    visitTimeUnit: null,
    visitPosSlackWindow: null,
    visitNegSlackWindow: null,
    visitUnscheduled: false,
    visitTimeUnitSelectOptions: [
      {
        text: "Please Select",
        value: null,
      },
      {
        text: "Minutes",
        value: "minutes",
      },
      {
        text: "Hours",
        value: "hours",
      },
      {
        text: "Days",
        value: "days",
      },
    ],
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async value(newVal) {
      if (this.visitId !== null) {
        this.formTitle = "Edit Visit";
        this.visit = await this.fetchVisitData(this.visitId);

        this.visitName = this.visit.name;
        this.visitSequence = this.visit.sequence;
        this.visitTimeDelay = this.visit.time_delay;
        this.visitTimeUnit = this.visit.time_unit;
        this.visitPosSlackWindow = this.visit.positive_slack_window;
        this.visitNegSlackWindow = this.visit.negative_slack_window;
        this.visitUnscheduled = this.visit.unscheduled;
      } else {
        this.formTitle = "New Visit";
        this.visitName = "";
        this.visitSequence = 1000;
        this.visitTimeDelay = null;
        this.visitTimeUnit = null;
        this.visitPosSlackWindow = null;
        this.visitNegSlackWindow = null;
        this.visitUnscheduled = false;
      }
    },
  },
  methods: {
    cancel() {
      this.show = false;
      this.$emit("cancel");
    },
    async updateOrCreateVisit() {
      if (this.visitName === "") {
        alert("Please enter a name for the visit.");
        return;
      }
      // console.log(this.visitTimeDelay);
      // if (this.visitTimeDelay === null || this.visitTimeDelay === "") {
      //   alert("Please enter a time delay for the visit. (0 is allowed)");
      //   return;
      // }
      // if (
      //   this.visitPosSlackWindow === null ||
      //   this.visitPosSlackWindow === ""
      // ) {
      //   alert(
      //     "Please enter a positive slack window for the visit. (0 is allowed)"
      //   );
      //   return;
      // }
      // if (
      //   this.visitNegSlackWindow === null ||
      //   this.visitNegSlackWindow === ""
      // ) {
      //   alert(
      //     "Please enter a negative slack window for the visit. (0 is allowed)"
      //   );
      //   return;
      // }

      const visitData = {
        name: this.visitName,
        sequence: this.visitSequence,
        time_delay: this.visitTimeDelay,
        time_unit: this.visitTimeUnit,
        positive_slack_window: this.visitPosSlackWindow,
        negative_slack_window: this.visitNegSlackWindow,
        unscheduled: this.visitUnscheduled,
        study_id: this.studyId,
      };

      let responseData = null;
      if (this.visitId !== null) {
        visitData.id = this.visitId;
        responseData = await this.updateStudyVisit(visitData);
      } else {
        responseData = await this.createStudyVisit(visitData);
      }

      if (responseData !== false) {
        this.$store.commit("mergeVisits", [responseData]);
      }
      this.$emit("callback", responseData);

      // reset form
      this.visitName = "";
      this.visitSequence = 1000;
      this.visitTimeDelay = null;
      this.visitTimeUnit = null;
      this.visitPosSlackWindow = null;
      this.visitNegSlackWindow = null;
      this.visitUnscheduled = false;

      this.show = false;
    },
  },
};
</script>
