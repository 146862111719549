<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card v-if="assessment != null || is_new">
        <v-card-title>
          <span v-if="is_new" class="text-h5">New Assessment</span>
          <span v-else class="text-h5">Edit {{ assessment.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  required
                  v-model="assessment.name"
                  class="required"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Version Number"
                  required
                  v-model="assessment.version_number"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="assessment.validated"
                  label="Validated"
                  :items="yes_no_options"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="assessment.archived"
                  label="Archived"
                  :items="yes_no_options"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  v-model="assessment.description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :disabled="save_loading"
            :loading="save_loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["assessmentId", "value", "is_new"],
  mixins: [hasuraService],
  data: () => ({
    save_loading: false,
    assessment: {
      name: "",
      description: "",
      version_number: "",
      validated: false,
      archived: false,
    },
    yes_no_options: [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ],
  }),
  watch: {
    async assessmentId(newVal, oldVal) {
      console.log("assessmentId", newVal, oldVal);
      if (newVal !== null && oldVal !== newVal) {
        const assessment = this.$store.getters.assessment(newVal);

        if (assessment === null) {
          this.assessment = await this.fetchAssessment(newVal);
        } else {
          this.assessment = JSON.parse(JSON.stringify(assessment));
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async _new() {
      if (this.assessment.name === "") {
        alert("Name is required");
        return;
      }
      await this.createAssessment({
        name: this.assessment.name,
        description: this.assessment.description,
        version_number: this.assessment.version_number,
        validated: this.assessment.validated,
        archived: this.assessment.archived,
      });
    },
    async _edit() {
      await this.updateAssessmentById({
        id: this.assessment.id, // this isnt updated, just used to find the correct record
        name: this.assessment.name,
        description: this.assessment.description,
        version_number: this.assessment.version_number,
        validated: this.assessment.validated,
        archived: this.assessment.archived,
      });
    },
    async save() {
      this.save_loading = true;
      if (this.is_new) {
        await this._new();
      } else {
        await this._edit();
      }
      this.save_loading = false;
      this.show = false;
      this.$emit("callback", this.assessment);
    },
    async fetchAssessment(id) {
      let d = await this.fetchAssessmentData(id);
      return JSON.parse(JSON.stringify(d));
    },
  },
};
</script>
