<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="290">
      <v-card v-if="visit !== null">
        <v-card-title class="text-h5">
          <span v-if="!archive">Archive</span>
          <span v-else>Unarchive</span>
          &nbsp;visit?
        </v-card-title>
        <v-card-text>{{ visit.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Cancel </v-btn>
          <v-btn color="error" @click="archiveStudyVisit(visitId)">
            <span v-if="!archive">Archive</span>
            <span v-else>Unarchive</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["visitId", "value", "archive"],
  mixins: [hasuraService],
  data: () => ({
    visit: null,
  }),
  watch: {
    async visitId(newVal, oldVal) {
      if (newVal !== null && oldVal !== newVal) {
        const visit = this.$store.getters.visit(newVal);

        if (visit === null) {
          this.visit = await this.fetchStudyVisit(newVal);
        } else {
          this.visit = visit;
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async fetchStudyVisit(id) {
      return this.fetchVisitData(id);
    },
    async archiveStudyVisit(id) {
      const result = await this.archiveStudyVisitById(id, !this.archive);
      this.$emit("callback", id);
      this.show = false;
      return result;
    },
  },
};
</script>
