<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="290">
      <v-card v-if="study !== null">
        <v-card-title class="text-h5">
          <span v-if="!setIsAchived">Unarchive</span>
          <span v-else>Archive</span> &nbsp;study?
        </v-card-title>
        <v-card-text>{{ study.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Cancel </v-btn>
          <v-btn color="error" @click="archiveStudy(studyId)">
            <span v-if="!setIsAchived">Unarchive</span>
            <span v-else>Archive</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["studyId", "value", "setIsAchived"],
  mixins: [hasuraService],
  data: () => ({
    study: null,
  }),
  watch: {
    async studyId(newVal, oldVal) {
      if (newVal !== null && oldVal !== newVal) {
        const study = this.$store.getters.study(newVal);

        if (study === null) {
          this.study = await this.fetchStudy(newVal);
        } else {
          this.study = study;
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async fetchStudy(id) {
      return this.fetchStudyData(id);
    },
    async archiveStudy(id) {
      const result = await this.archiveStudyById(id, this.setIsAchived);
      this.show = false;
      this.$emit("callback", id);
      return result;
    },
  },
};
</script>
