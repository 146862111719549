<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  name="studyName"
                  required
                  v-model="studyName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="studyDescription"
                  name="studyDescription"
                  label="Description"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Sponsor"
                  name="studySponsor"
                  required
                  v-model="studySponsor"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="studyEDC"
                  name="studyEDC"
                  :items="studyEDCSelectOptions"
                  label="Managing CRO"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="External ID Number"
                  required
                  v-model="studyExternalId"
                  name="studyExternalId"
                  type="number"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Medical Monitor Email"
                  required
                  v-model="medical_monitor_email"
                  name="medical_monitor_email"
                  hide-spin-buttons
                >
                  <v-tooltip slot="append" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" style="cursor: default">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    Group Emails are acceptable.
                  </v-tooltip>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="updateOrCreateStudy"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "studyId"],
  mixins: [hasuraService],
  data: () => ({
    isLoading: false,
    formTitle: "New Study",
    studyName: "",
    studyDescription: "",
    studySponsor: "",
    studyEDC: "",
    studyExternalId: "",
    studyEDCSelectOptions: ["iMednet", "Medidata Rave", "CRIO", "Oracle"],
    medical_monitor_email: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async studyId(newVal) {
      if (newVal !== null) {
        this.formTitle = "Edit Study";
        this.study = await this.fetchStudyData(this.studyId);

        this.studyName = this.study.name;
        this.studyDescription = this.study.description;
        this.studySponsor = this.study.sponsor;
        this.studyEDC = this.study.edc;
        this.studyExternalId = this.study.external_id;
        this.medical_monitor_email = this.study.medical_monitor_email;
      }
    },
  },
  methods: {
    async updateOrCreateStudy() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;
      let studyData = null;

      // validate medical_monitor_email format
      if (
        // I hate JS for this
        !(
          this.medical_monitor_email === "" ||
          this.medical_monitor_email === null ||
          this.medical_monitor_email === undefined
        )
      ) {
        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.medical_monitor_email)) {
          this.$store.commit("setSnack", {
            text: "Invalid email format for Medical Monitor Email. Please enter a valid email address.",
            color: "error",
          });
          this.isLoading = false;
          return false;
        }
      }

      if (this.studyId === null) {
        console.log("create study");
        studyData = await this.createStudy({
          name: this.studyName,
          description: this.studyDescription,
          edc: this.studyEDC,
          externalId: this.studyExternalId,
          sponsor: this.studySponsor,
          medical_monitor_email: this.medical_monitor_email || null,
        });
      } else {
        studyData = await this.updateStudyById({
          id: this.studyId,
          name: this.studyName,
          description: this.studyDescription,
          edc: this.studyEDC,
          externalId: this.studyExternalId,
          sponsor: this.studySponsor,
          medical_monitor_email: this.medical_monitor_email || null,
        });
      }

      if (studyData !== false) {
        this.$store.commit("mergeStudies", {
          studies: [studyData],
          reload: true,
        });
      }
      this.$emit("callback", studyData);
      this.isLoading = false;
      this.show = false;
      return true;
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id);
    },
  },
};
</script>
