<template>
  <v-card class="w-full">
    <v-card-title class="text-h5">
      <span v-if="!archive">Archive</span>
      <span v-else>Unarchive</span>
      &nbsp;{{ prettyName }}?
    </v-card-title>
    <v-card-text>{{ description }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="close"> Cancel </v-btn>
      <v-btn color="error" @click="archiveItem()">
        <span v-if="!archive">Archive</span>
        <span v-else>Unarchive</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: [
    "value", // model to show/hide
    "itemId",
    "archive",
    "tableName",
    "prettyName",
    "description",
    "archive_function", // function to call when archiving, if special case
  ],
  mixins: [hasuraService],
  data: () => ({
    visit: null,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async archiveItem() {
      if (this.archive_function) {
        await this.archive_function(!this.archive);
      } else {
        await this.genericArchive(this.itemId, !this.archive, this.tableName);
      }
      this.$emit("close");
    },
  },
};
</script>
