var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"my-2"},[_c('v-col',[_c('h1',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.assessment.name || "Loading...")+" Questions ")])]),_c('v-col',{staticClass:"text-right",attrs:{"align-self":"end"}},[_c('v-spacer'),_c('v-row',{attrs:{"justify":"end","align-self":"end"}},[(_vm.$store.state.user.admin)?_c('v-switch',{staticClass:"mr-3 mt-1",attrs:{"hide-details":"","label":"Archived Questions"},model:{value:(_vm.toggle_archive),callback:function ($$v) {_vm.toggle_archive=$$v},expression:"toggle_archive"}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary","to":{
            name: 'AssessmentNewQuestion',
            params: { id: _vm.assessment_id, type: 'new' },
          },"disabled":_vm.$store.state.app_locked}},[_vm._v(" New Question ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filtered_data,"items-per-page":-1,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.updated_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.$store.state.app_locked,"to":{
          name: 'AssessmenEditQuestion',
          params: { id: _vm.assessment_id, type: 'edit', question_id: item.id },
        }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil-outline ")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.$store.state.app_locked},on:{"click":function($event){return _vm.archiveQuestion(item.id)}}},[(!_vm.toggle_archive)?_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete-outline ")]):_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete-restore ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }