<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0 mt-4">
            <v-row>
              <v-col cols="12">
                <v-select
                  item-text="title"
                  item-value="value"
                  v-model="selectedAssessment"
                  :items="assessmentsSelectOptions"
                  label="Assessment"
                  dense
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Time delay"
                  required
                  type="number"
                  v-model="timeDelay"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="timeUnit"
                  :items="timeUnitSelectOptions"
                  label="Time unit"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Positive slack window"
                  required
                  type="number"
                  v-model="psw"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Negative slack window"
                  required
                  type="number"
                  v-model="nsw"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="required"
                  :items="yes_no_options"
                  label="Required"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Sequence"
                  required
                  type="number"
                  class="required"
                  v-model="sequence"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="allow_out_of_window_completion"
                  class="required"
                  :items="yes_no_options"
                  label="Allow Out of Window Completion"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="schedule_start_assessment"
                  :items="yes_no_options"
                  label="Schedule Start Assessment"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="interval_assessment_anchor_for_slack_window"
                  :items="interval_assessment_anchor_for_slack_window_options"
                  label="Anchor Assessment for Window"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="clinician_reported"
                  :items="yes_no_options"
                  label="Clinician Reported"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Business Logic"
                  v-model="business_logic"
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>

          <v-btn color="primary" @click="save" :loading="isLoading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "EditVisitAssessment",
  props: ["value", "visitAssessment", "study_id", "visit_id"],
  mixins: [hasuraService],
  data: () => ({
    timeUnitSelectOptions: [
      {
        text: "Please Select",
        value: null,
      },
      {
        text: "Minutes",
        value: "minutes",
      },
      {
        text: "Hours",
        value: "hours",
      },
      {
        text: "Days",
        value: "days",
      },
    ],
    selectedAssessment: null,
    formTitle: "New Visit Assessment",
    yes_no_options: [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ],
    interval_assessment_anchor_for_slack_window_options: [],
    isLoading: false,

    required: null,
    sequence: null,

    clinician_reported: null,
    allow_out_of_window_completion: null,
    schedule_start_assessment: null,
    interval_assessment_anchor_for_slack_window: null,

    assessmentsSelectOptions: [],

    psw: null,
    nsw: null,
    timeDelay: null,
    timeUnit: null,

    business_logic: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    visitAssessment: {
      handler(newValue) {
        console.log("visitAssessment", newValue);
        if (newValue !== null) {
          this.formTitle = "Edit Visit Assessment";
          this.selectedAssessment = this.visitAssessment.assessment_id;
          this.psw = this.visitAssessment.positive_slack_window;
          this.nsw = this.visitAssessment.negative_slack_window;
          this.timeDelay = this.visitAssessment.time_delay;
          this.timeUnit = this.visitAssessment.time_unit;
          this.required = this.visitAssessment.required;
          this.sequence = this.visitAssessment.sequence;

          this.clinician_reported = this.visitAssessment.clinician_reported;

          this.allow_out_of_window_completion =
            this.visitAssessment.allow_out_of_window_completion;
          this.schedule_start_assessment =
            this.visitAssessment.schedule_start_assessment;
          this.interval_assessment_anchor_for_slack_window =
            this.visitAssessment.interval_assessment_anchor_for_slack_window;
          this.business_logic = this.visitAssessment.business_logic;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAssessments();
    this.get_interval_assessment_anchor_for_slack_window_options();
  },
  methods: {
    async getAssessments() {
      const storedAssessments = this.$store.getters.assessments;
      if (Object.keys(storedAssessments).length > 0) {
        this.assessments = Object.values(storedAssessments);
      } else {
        this.assessments = await this.listAssessments();
        this.$store.commit("mergeAssessments", this.assessments);
      }

      for (let index = 0; index < this.assessments.length; index++) {
        const assessment = this.assessments[index];
        this.assessmentsSelectOptions.push({
          title: `${assessment.id}: ${assessment.name}`,
          value: assessment.id,
        });
      }
    },
    async get_interval_assessment_anchor_for_slack_window_options() {
      let data = await this.getIntervalAssessmentsForWindow(this.study_id);
      data = data.map((item) => {
        return {
          text: `ID: ${item.id} || Sequence ${item.sequence} || ${item.interval.name} || ${item.assessment.name}`,
          value: item.id,
        };
      });

      this.interval_assessment_anchor_for_slack_window_options = data;
    },
    async save() {
      if (this.isLoading === true) {
        return;
      }
      this.isLoading = true;

      if (this.selectedAssessment === null) {
        alert("Please select an assessment");
        this.isLoading = false;
        return;
      }

      if (this.allow_out_of_window_completion === null) {
        this.isLoading = false;
        alert("Please fill out Allow Out of Window Completion");
        this.isLoading = false;
        return;
      }

      if (this.sequence === null || this.sequence === "") {
        this.isLoading = false;
        alert("Please fill out Sequence");
        this.isLoading = false;
        return;
      }

      let d = {
        study_id: this.study_id,
        interval_id: this.visit_id,
        assessment_id: this.selectedAssessment,
        positive_slack_window: this.psw,
        negative_slack_window: this.nsw,
        time_delay: this.timeDelay,
        time_unit: this.timeUnit,
        clinician_reported: this.clinician_reported,
        allow_out_of_window_completion: this.allow_out_of_window_completion,
        schedule_start_assessment: this.schedule_start_assessment,
        required: this.required,
        sequence: this.sequence,
        interval_assessment_anchor_for_slack_window:
          this.interval_assessment_anchor_for_slack_window,
      };

      // change "" to null
      Object.keys(d).forEach((key) => d[key] === "" && (d[key] = null));

      // if business_logic is not empty, ensure it is a valid JSON
      if (this.business_logic !== "" && this.business_logic !== null) {
        try {
          JSON.parse(this.business_logic);
        } catch (e) {
          this.isLoading = false;
          alert("Business Logic must be a valid JSON");
          return;
        }
        d.business_logic = this.business_logic;
      }

      if (this.visitAssessment === null) {
        await this.newVisitAssessment(d);
      } else {
        await this.updateVisitAssessment(d);
      }
      this.isLoading = false;
      this.show = false;
    },
    async newVisitAssessment(data) {
      const addedVisitAssessment = await this.addVisitAssessmentRequest(data);

      this.isLoading = false;

      this.$emit("callback_add", addedVisitAssessment);
      this.show = false;
    },
    async updateVisitAssessment(data) {
      const visitAssessmentData = await this.updateVisitAssessmentById(
        this.visitAssessment.id,
        data
      );

      this.$emit("callback_edit", visitAssessmentData);
    },
  },
};
</script>
