<template>
  <div><span class="primary--text font-weight-bold">SDC</span><span
      class="secondary--text font-weight-light pr-10">CAPTURE</span></div>
</template>

<script>
export default {
  name: 'BrandingComponent',
  data: () => ({}),
};
</script>
