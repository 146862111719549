<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">Assessments</h1>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Assessments"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>

          <v-btn
            color="primary"
            @click="newAssessment"
            :disabled="$store.state.app_locked"
            >New Assessment</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.questions="{ item }">
        <v-badge
          v-if="item.questions.length > 0"
          class="text-overline badge-size-small"
          color="secondary"
          :content="item.questions.length"
          offset-y="-2"
        />
        <v-btn
          icon
          small
          :to="{ name: 'AssessmentQuestions', params: { id: item.id } }"
        >
          <v-icon dark> mdi-table </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :to="{
            name: 'AssessmentNewQuestion',
            params: { id: item.id, type: 'new' },
          }"
          :disabled="$store.state.app_locked"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-2"
          icon
          small
          @click="editAssessment(item.id)"
          :disabled="item.validated === true || $store.state.app_locked"
        >
          <v-icon dark> mdi-pencil-outline </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="archiveAssessment(item.id)"
          :disabled="$store.state.app_locked"
        >
          <v-icon v-if="toggle_archive" dark> mdi-delete-restore </v-icon>
          <v-icon v-else dark> mdi-delete-outline </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <archive-assessment-dialog
      v-model="showArchiveAssessmentDialog"
      v-bind:assessmentId="archiveAssessmentId"
      v-bind:archive="toggle_archive"
      @callback="archiveAssessmentCallback"
    ></archive-assessment-dialog>
    <!-- <new-assessment-form v-model="showNewAssessmentForm" @callback="newAssessmentCallback"></new-assessment-form> -->
    <edit-assessment-form
      v-model="showEditAssessmentForm"
      v-bind:assessmentId="editAssessmentId"
      v-bind:is_new="false"
      @callback="editAssessmentCallback"
    ></edit-assessment-form>
    <edit-assessment-form
      v-model="showNewAssessmentForm"
      v-bind:assessmentId="null"
      v-bind:is_new="true"
      @callback="newAssessmentCallback"
    ></edit-assessment-form>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import ArchiveAssessmentDialog from "@/components/assessments/ArchiveAssessmentDialog.vue";
// import NewAssessmentForm from '@/components/assessments/NewAssessmentForm.vue';
import EditAssessmentForm from "@/components/assessments/EditAssessmentForm.vue";

export default {
  name: "AssessmentsView",
  mixins: [hasuraService],
  components: {
    "archive-assessment-dialog": ArchiveAssessmentDialog,
    // 'new-assessment-form': NewAssessmentForm,
    "edit-assessment-form": EditAssessmentForm,
  },

  data() {
    return {
      loading: true,
      toggle_archive: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Questions", value: "questions", width: "110px" },
        { text: "Actions", value: "actions", width: "110px" },
      ],
      study: null,
      visit: null,
      breadcrumbs: [],
      data: [],
      assessments: {},
      archiveAssessmentId: null,
      showArchiveAssessmentDialog: false,
      showNewAssessmentForm: false,
      showEditAssessmentForm: false,
      editAssessmentId: null,
    };
  },
  watch: {
    toggle_archive: {
      handler: function (val) {
        this.getAssessments();
      },
    },
  },
  methods: {
    archiveAssessment(id) {
      this.archiveAssessmentId = id;
      this.showArchiveAssessmentDialog = true;
    },
    archiveAssessmentCallback(assessmentId) {
      this.data = this.data.filter((element) => element.id !== assessmentId);
    },
    newAssessment() {
      this.showNewAssessmentForm = true;
    },
    async newAssessmentCallback() {
      await this.getAssessments(false);
    },
    editAssessment(id) {
      this.editAssessmentId = id;
      this.showEditAssessmentForm = true;
    },
    editAssessmentCallback(assessmentData) {
      this.data = this.data.map((element) => {
        if (element.id === assessmentData.id) {
          return assessmentData;
        }
        return element;
      });
    },
    async getAssessments(cache = true) {
      this.assessments = await this.listAssessments(this.toggle_archive, cache);
      this.data = this.assessments;
    },
  },
  async mounted() {
    await this.getAssessments();
    this.loading = false;
  },
};
</script>
