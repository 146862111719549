<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Manage User Study Sites</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="data"
            :items-per-page="-1"
            class="elevation-1"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <!-- <v-icon v-if="item.checked" color="primary">mdi-check-circle</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon> -->
                  <v-checkbox v-model="item.checked"></v-checkbox>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveUserStudySites"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "user", "studyId"],
  mixins: [hasuraService],
  data: () => ({
    loaded: false,
    isSaving: false,
    loading: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Name", value: "name" },
      { text: "Assigned", value: "actions", width: "120px" },
    ],
    data: [],
    attachStudyError: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async user(newVal) {
      if (newVal !== null) {
        /// todo: implement
      }
    },
    studyId: {
      immediate: true,
      async handler(newVal) {
        if (newVal !== null) {
          this.loading = true;
          const tmpData = [];
          this.study = await this.fetchStudy(newVal);
          for (let i = 0; i < this.study.study_sites.length; i++) {
            const studySite = this.study.study_sites[i];
            // find site in user_studies object
            const userStudyData = this.user.user_studies.find(
              (userStudy) => userStudy.study_id === this.study.id
            );
            console.log("userStudyData", userStudyData);
            const checked = userStudyData.study.user_study_sites.some(
              (userStudySite) => userStudySite.site_id === studySite.site_id
            );
            tmpData.push({
              id: studySite.site_id,
              name: studySite.site_name,
              checked,
            });
          }

          this.data = tmpData;
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async saveUserStudySites() {
      if (this.isSaving) {
        return false;
      }

      this.isSaving = true;

      if (!this.study || !this.user) {
        this.isSaving = false;
        return false;
      }

      const sitesIds = this.data
        .filter((item) => item.checked)
        .map((item) => item.id);
      const result = await this.replaceUserStudySites(
        this.user.id,
        this.study.id,
        sitesIds
      );

      this.$emit("callback", result);
      this.show = false;
      this.isSaving = false;
      return true;
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id, false);
    },
  },
  mounted() {},
};
</script>
