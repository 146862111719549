<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">Study Sites</h1>
        <v-breadcrumbs :items="breadcrumbs" class="pl-0 pt-2 pb-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Study Sites"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>
          <v-btn color="secondary" :to="{ name: 'Studies' }" class="mr-3">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            @click="addStudySite"
            :disabled="$store.state.app_locked"
          >
            Add Site
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item="{ item }">
        <tr>
          <!-- "Alternative Site Name" -->
          <td>Study: {{ item.study_id }} || Site: {{ item.site_id }}</td>
          <td>{{ item.site_name }}</td>
          <!-- "Alternative Site Identifier" -->
          <td>{{ item.site_identifier }}</td>
          <td>{{ item.site.address }}</td>
          <td>{{ item.site.city }}</td>
          <td>{{ item.site.state }}</td>
          <td>{{ item.site.zip }}</td>
          <td>
            <v-btn
              class="mx-1"
              icon
              small
              @click="editStudySite(item)"
              :disabled="$store.state.app_locked"
            >
              <v-icon dark> mdi-pencil-outline </v-icon>
            </v-btn>
            <v-btn
              class="mx-1"
              icon
              small
              @click="detachStudySite(item)"
              v-if="!item.archived"
              :disabled="$store.state.app_locked"
            >
              <v-icon dark> mdi-delete-outline </v-icon>
            </v-btn>
            <v-btn
              class="mx-1"
              icon
              small
              @click="detachStudySite(item)"
              :disabled="$store.state.app_locked"
              v-if="item.archived"
              title="Unarchive Study"
            >
              <v-icon dark> mdi-delete-restore </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <add-site-form
      v-model="showAddStudySiteFormDialog"
      action="add"
      v-bind:studyId="addSiteFormStudyId"
      @callback="addStudySiteCallback"
    ></add-site-form>
    <add-site-form
      v-if="showEditStudySiteFormDialog"
      v-model="showEditStudySiteFormDialog"
      action="edit"
      v-bind:studyId="addSiteFormStudyId"
      v-bind:selectedStudySite="selectedStudySite"
      @callback="editStudySiteCallback"
    ></add-site-form>
    <detach-study-site-dialog
      v-model="showDetachStudySiteDialog"
      v-bind:studySite="detachStudySiteItem"
      v-bind:archive="toggle_archive"
      @callback="detachStudySiteCallback"
    ></detach-study-site-dialog>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import AddSiteForm from "@/components/studies/AddSiteForm.vue";
import DetachStudySiteDialog from "@/components/studies/DetachStudySiteDialog.vue";

export default {
  name: "StudySitesView",
  mixins: [hasuraService],
  components: {
    "add-site-form": AddSiteForm,
    "detach-study-site-dialog": DetachStudySiteDialog,
  },

  data() {
    return {
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "site_name" },
        { text: "Site Identifier", value: "site_identifier" },
        { text: "Address", value: "address" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Zip", value: "zip" },
        { text: "Actions", value: "actions", width: "170px" },
      ],
      breadcrumbs: [
        {
          text: "Studies",
          disabled: false,
          to: { name: "Studies" },
          exact: true,
        },
        {
          text: "Study name",
          disabled: true,
        },
      ],
      data: [],
      study: null,
      addSiteFormStudyId: null,
      showAddStudySiteFormDialog: false,
      showEditStudySiteFormDialog: false,
      showDetachStudySiteDialog: false,
      detachStudySiteItem: null,
      archive: false,
      toggle_archive: false,
      selectedStudySite: null,
    };
  },
  watch: {
    toggle_archive: {
      handler: function (val) {
        // it loads so fast, we don't need a loading indicator
        // but we can add it later if needed
        // this.loading = true;

        this.fetchStudySiteData(
          this.$route.params.id,
          this.toggle_archive,
          true // force update
        ).then((study_sites) => {
          // this.loading = false;
          this.data = study_sites;
        });
      },
    },
  },
  methods: {
    async setBreadcrumbs() {
      if (this.study && this.study.name) {
        this.breadcrumbs = [
          {
            text: "Studies",
            disabled: false,
            to: { name: "Studies" },
            exact: true,
          },
          {
            text: this.study.name,
            disabled: true,
          },
        ];
      }
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id, this.toggle_archive);
    },
    async editStudySite(studySite) {
      this.addSiteFormStudyId = this.study.id;
      this.selectedStudySite = studySite;
      this.showEditStudySiteFormDialog = true;
    },
    async addStudySite() {
      this.addSiteFormStudyId = this.study.id;
      this.showAddStudySiteFormDialog = true;
    },
    async addStudySiteCallback(siteData) {
      this.study.study_sites.push(siteData);
      this.data = this.study.study_sites.filter(
        (site) => site.archived === this.toggle_archive
      );
    },
    async editStudySiteCallback(siteData) {
      const studySite = this.study.study_sites.find(
        (site) => site.site.id === siteData.site.id
      );
      console.log("studySite", studySite);
      const index = this.study.study_sites.indexOf(studySite);
      console.log("index", index);
      this.study.study_sites[index] = siteData;
      // force update
      // filtered out archived sites
      this.data = this.study.study_sites.filter(
        (site) => site.archived === this.toggle_archive
      );
    },
    async detachStudySite(studySite, archive) {
      console.log("detachStudySite", studySite);
      this.detachStudySiteItem = studySite;
      this.showDetachStudySiteDialog = true;
    },
    async detachStudySiteCallback(siteId) {
      console.log("detachStudySiteCallback", siteId);
      this.data = this.data.filter((site) => site.site.id !== siteId);
    },
  },
  async mounted() {
    // const study = this.$store.getters.study(this.$route.params.id);
    this.study = await this.fetchStudy(this.$route.params.id);
    this.study = JSON.parse(JSON.stringify(this.study));

    this.data = await this.fetchStudySiteData(
      this.$route.params.id,
      this.toggle_archive
    );

    this.setBreadcrumbs();
    this.loading = false;
  },
};
</script>
