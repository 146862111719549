import Vue from 'vue';
import VueRouter from 'vue-router';

import { authRoutes } from './authRoutes';
import { studyRoutes } from './studiesRoutes';
import { userRoutes } from './userRoutes';
import { assessmentRoutes } from './assessmentRoutes';
import { sitesRoutes } from './sitesRoutes';
import { dataRoutes } from './dataRoutes';
// import store
import store from '../store/index';
import hasuraService from '../services/hasura.service';

Vue.use(VueRouter);

const routes = [];

routes.push(...authRoutes);
routes.push(...studyRoutes);
routes.push(...userRoutes);
routes.push(...assessmentRoutes);
routes.push(...sitesRoutes);
routes.push(...dataRoutes);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {

  const token = localStorage.getItem('token');
  const isAuthenticated = !!token;
  if (!['Login', 'ForgotPassword', 'ResetPassword'].includes(to.name) && !isAuthenticated) next({ name: 'Login' });

  let base_url = window.location.origin;

  // clinicians can only access the datatable
  await store.state.jwt_loading;
  if (store.state.user.role == "clinician" && to.name != "DataTable") {
    // NOTE: Do ~~NOT~~ await this call
    hasuraService.methods.trackPageView(router.app.$apolloProvider, store.state.user.email, base_url + '/datatable');
    next({ name: 'DataTable' });
  }

  if (isAuthenticated) {
    // NOTE: Do ~~NOT~~ await this call
    hasuraService.methods.trackPageView(router.app.$apolloProvider, store.state.user.email, base_url + to.path);
  }

  next();
});

export default router;
