<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="font-weight-regular">
          {{ assessment.name || "Loading..." }} Questions
        </h1>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Questions"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>

          <v-btn
            color="primary"
            :to="{
              name: 'AssessmentNewQuestion',
              params: { id: assessment_id, type: 'new' },
            }"
            :disabled="$store.state.app_locked"
          >
            New Question
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filtered_data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.updated_at="{ item }">
        {{ formatDate(item.updated_at) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          small
          :disabled="$store.state.app_locked"
          :to="{
            name: 'AssessmenEditQuestion',
            params: { id: assessment_id, type: 'edit', question_id: item.id },
          }"
        >
          <v-icon dark> mdi-pencil-outline </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="archiveQuestion(item.id)"
          :disabled="$store.state.app_locked"
        >
          <v-icon dark v-if="!toggle_archive"> mdi-delete-outline </v-icon>
          <v-icon dark v-else> mdi-delete-restore </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import moment from "moment";

export default {
  name: "AssessmentQuestions",
  mixins: [hasuraService],
  components: {},

  async mounted() {
    this.assessment_id = this.$route.params.id;
    await this.getQuestions();
  },

  data() {
    return {
      loading: true,
      toggle_archive: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Label", value: "label" },
        { text: "Question Type", value: "question_type" },
        { text: "Archived", value: "archived" },
        { text: "Last Updated", value: "updated_at" },
        { text: "Options", value: "options" },
        { text: "Sequence", value: "sequence" },

        { text: "Actions", value: "actions", width: "110px" },
      ],
      data: [],
      assessment: {},
      assessment_id: null,
    };
  },
  computed: {
    filtered_data() {
      return this.data.filter((item) => {
        return item.archived === this.toggle_archive;
      });
    },
  },
  methods: {
    async _archiveQuestion(question_id) {
      let result = await this.archiveQuestionById(
        question_id,
        !this.toggle_archive
      );
      // I hate this, when we have a chance we should change this to an optimistic update
      this.data = await this.listQuestionsbyAssessmentId(
        this.assessment_id,
        false
      );
    },
    async archiveQuestion(question_id) {
      let txt = this.toggle_archive
        ? "Are you sure you want to restore this question"
        : "Are you sure you want to archive this question";
      if (confirm(txt)) {
        await this._archiveQuestion(question_id);
        let index = this.data.findIndex((item) => item.id === question_id);
        let local_data = JSON.parse(JSON.stringify(this.data));
        local_data[index].archived = !this.toggle_archive;
        this.data = local_data;
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async getQuestions() {
      this.loading = true;

      this.assessment = JSON.parse(
        JSON.stringify(await this.fetchAssessmentData(this.assessment_id))
      );

      let result = JSON.parse(
        JSON.stringify(
          await this.listQuestionsbyAssessmentId(this.assessment_id)
        )
      );
      console.log(result);
      result.map((item) => {
        item.options = item.options_aggregate.aggregate.count;
      });

      this.data = [];

      this.data = result;
      this.loading = false;
    },
  },
};
</script>
