import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: window.localStorage.getItem('token'),
    jwt_loading: false,
    // prod is not allowed to edit studies
    app_locked: process.env.VUE_APP_LOCKED === 'TRUE',
    user: {},
    sites: {},
    studies: {},
    visits: {},
    assessments: {},
    snack: {
      show: false,
      text: '',
      color: 'success',
    },
  },
  getters: {
    sites(state) {
      return state.sites;
    },
    studies(state) {
      return state.studies;
    },
    visits(state) {
      return state.visits;
    },
    assessments(state) {
      return state.assessments;
    },
    study: (state) => (id) => (id in state.studies ? state.studies[id] : null),
    visit: (state) => (id) => (id in state.visits ? state.visits[id] : null),
    assessment: (state) => (id) => (id in state.assessments ? state.assessments[id] : null),
    isAuthenticated(state) {
      return !!state.token;
    },
  },
  mutations: {
    setSnack(state, snack) {
      console.log('setSnack', snack);
      state.snack = {
        show: true,
        text: snack.text,
        color: snack.color || 'success',
      };
    },
    setUser(state, userData) {
      state.user = userData;
    },

    mergeSites(state, sitesData) {
      const { sites, reload } = sitesData;
      for (let index = 0; index < sites.length; index++) {
        const siteData = sites[index];
        if (reload || !(siteData.id in state.sites)) {
          state.sites[siteData.id] = siteData;
        }
      }
    },
    mergeStudies(state, studiesData) {
      const { studies, reload } = studiesData;
      for (let index = 0; index < studies.length; index++) {
        const studyData = studies[index];
        if (reload || !(studyData.id in state.studies)) {
          state.studies[studyData.id] = studyData;
        }
      }
    },
    updateStudy(store, studyData) {
      store.studies[studyData.id] = studyData;
    },
    removeStudyFromStore(store, studyId) {
      if (studyId in store.studies) {
        delete store.studies[studyId];
      }
    },
    mergeVisits(state, visits) {
      for (let index = 0; index < visits.length; index++) {
        const visitData = visits[index];
        if (!(visitData.id in state.visits)) {
          state.visits[visitData.id] = visitData;
        }
      }
    },
    updateStudyVisit(store, visitData) {
      store.visits[visitData.id] = visitData;
    },
    removeStudyVisitFromStore(store, visitId) {
      if (visitId in store.visits) {
        delete store.visits[visitId];
      }
    },
    mergeAssessments(state, assessments) {
      for (let index = 0; index < assessments.length; index++) {
        const assessmentData = assessments[index];
        if (!(assessmentData.id in state.assessments)) {
          state.assessments[assessmentData.id] = assessmentData;
        }
      }
    },
    removeAssessmentFromStore(store, assessmentId) {
      if (assessmentId in store.assessments) {
        delete store.assessments[assessmentId];
      }
    },
  },
  actions: {

  },
  modules: {
  },
});
