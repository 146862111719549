<template>
  <v-container bg fill-height grid-list-md text-xs-center>
    <v-layout row wrap align-center>
      <v-flex align-center>
        <v-card class="pa-8 ma-auto" max-width="400">
          <v-form ref="form" v-model="valid" @keyup.enter="submit">
            <v-row no-gutters class="mb-2">
              <p class="text-h6 font-weight-regular">
                <branding></branding>
              </p>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  name="email"
                  required
                  outlined
                  clearable
                  :rules="emailRules"
                  v-on:keyup.enter="submit"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  name="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  label="Password"
                  required
                  outlined
                  clearable
                  :rules="passwordRules"
                  @click:append="show1 = !show1"
                  v-on:keyup.enter="submit"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="pa-0">
              <v-row justify="end" class="ma-0">
                <v-btn
                  text
                  href="/forgot-password"
                  class="secondary--text mr-4"
                >
                  Forgot password?
                </v-btn>
                <v-btn color="primary" :disabled="!valid" @click="submit">
                  <span v-if="!isLoading">Sign In</span>
                  <v-progress-circular
                    indeterminate
                    color="onPrimary"
                    width="2"
                    class="ma-1"
                    v-else
                  ></v-progress-circular>
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>

    <v-alert type="error" class="fp-alert" v-model="showError">
      <v-row>
        <v-col class="grow">
          {{ errorMessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="showError = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import Branding from "@/components/Branding.vue";

export default {
  name: "LoginView",
  components: {
    branding: Branding,
  },
  mixins: [hasuraService],

  data() {
    return {
      showError: false,
      errorMessage: "",
      loading: false,
      valid: false,
      show1: false,
      email: "",
      password: "",
      name: "password-input",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 4 || "Password must be at least 10 characters",
      ],
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  mounted: function () {
    let endpoints = [
      "https://com-sdcclinical-eyecup-dev.azurewebsites.net/api/version",
      "https://com-sdcclinical-eyecup-uat.azurewebsites.net/api/version",
      "https://com-sdcclinical-eyecup-prod.azurewebsites.net/api/version",
      "https://com-sdcclinical-eyecup-staging.azurewebsites.net/api/version",
    ];
    for (let index = 0; index < endpoints.length; index++) {
      const element = endpoints[index];
      fetch(element);
    }
  },
  methods: {
    submit() {
      const { form } = this.$refs;
      form.validate();
      if (this.valid) {
        this.loading = true;
        window.localStorage.clear();
        this.login(this.email, this.password).then((result) => {
          this.loading = false;
          if (result.success) {
            // We are using a hard href to refresh the page and clear the cache
            let next_page = localStorage.getItem("next_page");
            // If the next page is null, we will redirect to the home page
            if (next_page == null) {
              next_page = "/";
            }
            if (next_page.includes("login")) {
              next_page = "/";
            }

            document.location.href = next_page ? next_page : "/";
            localStorage.removeItem("next_page");
          } else {
            this.showError = true;
            this.errorMessage = result.reason;
          }
        });
      }
    },
  },
};
</script>
