<template>
  <v-container bg fill-height grid-list-md text-xs-center>
    <v-layout row wrap align-center>

      <v-flex align-center>
        <v-card class="pa-8 ma-auto" max-width="400">
          <v-row v-if="requestSent" no-gutters>
            <v-col>
              <p class="text-h6 font-weight-regular">Your password has been reset successfully</p>
            </v-col>
          </v-row>
          <v-form v-else ref="form" v-model="valid" @keyup.enter="validateAndSubmit">
            <v-row no-gutters class="mb-2">
              <p class="text-h6 font-weight-regular">Reset Password</p>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-text-field v-model="password" label="Password" :type="passwordFieldType" required outlined clearable
                  single-line :rules="passwordRules" :append-icon="passwordFieldIcon"
                  @click:append="togglePasswordShow"></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col>
                <v-text-field v-model="passwordConfirmation" label="Password Confirmation"
                  :type="passwordConfirmationFieldType" required outlined clearable single-line
                  :rules="passwordConfirmationRules" :append-icon="passwordConfirmationFieldIcon"
                  @click:append="togglePasswordConfirmationShow"></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="pr-0">
              <v-row justify="end" class="ma-0">
                <v-btn color="primary" :disabled="!valid" @click="validateAndSubmit" :loading="isLoading">Submit</v-btn>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>

    <v-alert type="error" class="fp-alert" v-model="showError">
      <v-row>
        <v-col class="grow">
          {{ errorMessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="onErrorActionPressed"><v-icon>mdi-email</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert type="success" class="fp-alert" v-model="showSuccess">
      <v-row>
        <v-col class="grow">
          {{ successMessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="onSuccessActionPressed">OK</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import hasuraService from '@/services/hasura.service.js';

export default {
  name: 'ResetPassword',
  mixins: [hasuraService],

  data() {
    return {
      valid: false,
      password: '',
      passwordConfirmation: '',
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v.length >= 14 && v.length <= 50) || 'Password must be between 14 and 50 characters',
      ],
      passwordConfirmationRules: [
        (v) => !!v || 'Password confirmation is required',
        (v) => v === this.password || 'Password confirmation must match password',
      ],
      showPassword: false,
      showPasswordConfirmation: false,
      requestSent: false,
      userType: null,
      token: null,
      showError: false,
      errorMessage: 'Server error. Please, contact support.',
      successMessage: 'Your password has been reset. You can now log in.',
      showSuccess: false,
      isLoading: false,
    };
  },
  async mounted() {
    this.userType = this.$route.params.usertype;
    this.token = this.$route.params.token;
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordFieldIcon() {
      return !this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },
    passwordConfirmationFieldType() {
      return this.showPasswordConfirmation ? 'text' : 'password';
    },
    passwordConfirmationFieldIcon() {
      return !this.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off';
    },
  },
  methods: {
    togglePasswordShow() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordConfirmationShow() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
    },
    onError(message) {
      this.errorMessage = `${message}. Please, contact support.`;
      this.showError = true;
    },
    onErrorActionPressed() {
      this.showError = false;
    },
    onSuccess() {
      this.successMessage = 'Your password has been reset. You can now log in.';
      this.showSuccess = true;
      this.showError = false;
    },
    onSuccessActionPressed() {
      this.showSuccess = false;
    },
    /// I could not find a way to mock $refs in vitest so I had to move this logic to a separate method
    async validateAndSubmit() {
      const { form } = this.$refs;
      form.validate();

      if (this.valid) {
        this.submit();
      }
    },
    async submit() {
      this.isLoading = true;


      try {
        const result = await this.passwordResetStepTwo(this.password, this.userType, this.token);
        if (result.errors && result.errors.length > 0) {
          this.onError(result.errors[0].message);
          this.isLoading = false;
          return false;
        }

        if (result.result) {
          this.requestSent = true;
          this.onSuccess();
          // setTimeout(() => {
          //   this.$router.push({ name: 'Login' });
          // }, 2000);
          return true;
        }

        this.onError(result.reason);
      } catch (error) {
        this.onError(error);
      }

      this.isLoading = false;
      return false;
    },
  },
};
</script>
