<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="400">
      <v-card v-if="assessment !== null">
        <v-card-title class="text-h5">
          <span v-if="!archive">Archive</span>
          <span v-else>Unarchive</span>
          &nbsp;assessment?
        </v-card-title>
        <v-card-text>{{ assessment.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Cancel </v-btn>
          <v-btn color="error" @click="archiveAssessment(assessmentId)">
            <span v-if="!archive">Archive</span>
            <span v-else>Unarchive</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["assessmentId", "value", "archive"],
  mixins: [hasuraService],
  data: () => ({
    assessment: null,
  }),
  watch: {
    async assessmentId(newVal, oldVal) {
      if (newVal !== null && oldVal !== newVal) {
        const assessment = this.$store.getters.assessment(newVal);

        if (assessment === null) {
          this.assessment = await this.fetchAssessment(newVal);
        } else {
          this.assessment = assessment;
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async fetchAssessment(id) {
      return this.fetchAssessmentData(id);
    },
    async archiveAssessment(id) {
      let local_assessment = JSON.parse(JSON.stringify(this.assessment));
      delete local_assessment.__typename;
      delete local_assessment.questions;
      delete local_assessment.created_at;

      const result = await this.updateAssessmentById({
        ...local_assessment,
        archived: !this.archive,
      });
      this.show = false;
      this.$emit("callback", id);
      return result;
    },
  },
};
</script>
