<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">
          Visits Schedule
        </h1>
        <v-breadcrumbs :items="breadcrumbs" class="pl-0 pt-2">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-data-table hide-default-footer :headers="headers" :items="data" :items-per-page="-1" class="elevation-1" :loading="loading" loading-text="Loading... Please wait">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.assessmentName }}</td>
          <td v-for="visit in item.visits" v-bind:key="visit.id">
            <v-icon v-if="visit.checked" color="primary">mdi-check-circle</v-icon>
            <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
          </td>
        </tr>

      </template>
    </v-data-table>

  </v-container>
</template>

<script>
import hasuraService from '@/services/hasura.service.js';

export default {
  name: 'VisitsSchedule',
  mixins: [hasuraService],
  components: {
  },

  data() {
    return {
      loading: true,
      headers: [],
      breadcrumbs: [
        {
          text: 'Studies',
          disabled: false,
          to: { name: 'Studies' },
          exact: true,
        },
        {
          text: 'Visits',
          disabled: false,
          to: { name: 'Visits', params: { id: this.$route.params.id } },
          exact: true,
        },
        {
          text: 'Study name',
          disabled: true,
        },
      ],
      data: [],
      study: null,
    };
  },
  methods: {
    async setBreadcrumbs() {
      const study = this.$store.getters.study(this.$route.params.id);

      if (study === null) {
        this.study = await this.fetchStudy(this.$route.params.id);
      } else {
        this.study = study;
      }

      if (this.study && this.study.name) {
        this.studyId = this.study.id;

        this.breadcrumbs = [
          {
            text: 'Studies',
            disabled: false,
            to: { name: 'Studies' },
            exact: true,
          },
          {
            text: this.study.name,
            disabled: false,
            to: { name: 'Visits', params: { id: this.$route.params.id } },
            exact: true,
          },
          {
            text: 'Schedule',
            disabled: true,
          },
        ];
      }
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id);
    },
    async getData() {
      const studyId = this.$route.params.id;

      // fetch assessments
      const assessments = await this.listAssessmentsByStudyId(studyId);

      // fetch visits
      this.visits = await this.listVisits(this.$route.params.id);
      this.$store.commit('mergeVisits', this.visits);

      // sort visits by sequense number
      // visits.sort((a, b) => a.sequence - b.sequence);

      // generate table header
      this.headers.push({ text: 'Assessment Name', value: 'assessmentName', width: '400px' });
      for (let index = 0; index < this.visits.length; index++) {
        const visit = this.visits[index];

        this.headers.push({ text: visit.name, value: `visit_${visit.id}`, width: '100px' });
      }

      // table data (todo: get rid of nested loops / fix time complexity)
      for (let index = 0; index < assessments.length; index++) {
        const assessment = assessments[index];
        const rowData = {
          assessmentId: assessment.id,
          assessmentName: assessment.name,
          visits: [],
        };

        for (let ind = 0; ind < this.visits.length; ind++) {
          const visit = this.visits[ind];
          const isChecked = this.ascertainCheckboxStatus(visit, assessment);
          rowData.visits.push({
            id: visit.id,
            checked: isChecked,
          });
        }

        this.data.push(rowData);
      }
    },
    ascertainCheckboxStatus(visit, assessment) {
      if (visit.interval_assessments.length > 0) {
        const result = visit.interval_assessments.find((el) => el.assessment_id === assessment.id);
        if (result !== undefined) {
          return true;
        }
      }

      return false;
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    await this.getData();
    this.loading = false;
  },
};
</script>
