<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          @change="update_site_list"
          :items="study_list"
          v-model="study_id"
          label="Study"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="update_assessment_list"
          :disabled="study_id == null"
          v-model="selected_site"
          :items="site_list"
          label="Site"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="main_run"
          :disabled="selected_site == null"
          :items="assessment_list"
          v-model="assessment_id"
          label="Assessment"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="main_run"
          :disabled="assessment_id == null"
          v-model="selected_subject"
          :items="subjects"
          label="Subject (optional)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="main_run"
          :disabled="assessment_id == null"
          v-model="selected_visit"
          :items="visits"
          label="Visit (optional)"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col>
        <v-select></v-select>
      </v-col> -->
    </v-row>
    <v-row v-if="ready">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filtered_data"
          :items-per-page="-1"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <!-- <vue-json-to-csv :json-data="jsonData">
                <v-btn color="secondary" class="mr-2">Export to CSV</v-btn>
              </vue-json-to-csv> -->
              <v-btn
                color="primary"
                :loading="pdf_loading"
                :disabled="pdf_loading"
                @click="export_to_pdf"
                >Export to PDF</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span>{{ date_fmt(item.created_at) }}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <span>{{ date_fmt(item.created_at) }}</span>
          </template>

          <template v-slot:body="{ items }">
            <tr v-for="idx in items">
              <td v-for="header in headers" class="text-center">
                <span v-if="!is_media(idx[header.value])">
                  <span
                    v-if="
                      header.value == 'created_at' ||
                      header.value == 'updated_at' ||
                      header.value == 'submitted_at'
                    "
                  >
                    {{ date_fmt(idx[header.value]) }}
                  </span>
                  <span v-else>
                    {{ idx[header.value] }}
                  </span>
                </span>
                <span v-else>
                  <v-btn
                    @click="get_srs_tokens(idx[header.value])"
                    text
                    color="primary"
                    >View Img</v-btn
                  >
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Image </v-card-title>

        <v-card-text>
          <a target="_blank" :href="sas_token_path"
            ><img
              style="max-width: 400px; max-height: 400px"
              :src="sas_token_path"
              alt="image"
          /></a>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import VueJsonToCsv from "vue-json-to-csv";
import gql from "graphql-tag";
import axios from "axios";

//   dia_app_record(where: {study_id: {_eq: 24}, interval_assessment_id: {_eq: 40}, id: {_eq: 324}}) {
const MAIN_QUERY = gql`
query GetAssessmentData($assessment_id: Int!, $study_id: Int!, $user_id: Int!, $site_id: Int!) {
  dia_app_record(where: {study: {user_studies: {user_id: {_eq: $user_id}}, user_study_sites: {user_id: {_eq: $user_id}}}, study_id: {_eq: $study_id}, site_id: {_eq: $site_id}, interval_assessment: {assessment_id: {_eq: $assessment_id}}}) {
    study {
      name
      study_identifier
    }

    interval_assessment {
      assessment {
        name
        assessment_key
      }
      interval {
        name
      }
    }
    site {
      name
      identifier
    }
    subject {
      subject_full_id
    }
    id
    created_at
    updated_at
    submitted_at
    status
    archived
    out_of_assessment_window_completion
    out_of_interval_window_completion
    responses(order_by: {question: {sequence: asc}}) {
      value
      side
      media {
        id
      }
      question {
        sequence
        label
        variable_name
      }
      option {
        label
        value
      }
    }
  }
}
`;

const MAIN_QUERY_ADMIN = gql`
query GetAssessmentData($assessment_id: Int!, $study_id: Int!, $site_id: Int!) {
  dia_app_record(where: {study: {user_studies: {user_id: {}}, user_study_sites: {user_id: {}}}, study_id: {_eq: $study_id}, site_id: {_eq: $site_id}, interval_assessment: {assessment_id: {_eq: $assessment_id}}}) {
    study {
      name
      study_identifier
    }
    interval_assessment {
      assessment {
        name
        assessment_key
      }
      interval {
        name
      }
    }
    site {
      name
      identifier
    }
    subject {
      subject_full_id
    }
    id
    created_at
    updated_at
    submitted_at
    status
    archived
    out_of_assessment_window_completion
    out_of_interval_window_completion
    responses(order_by: {question: {sequence: asc}}) {
      value
      side
      media {
        id
      }
      question {
        sequence
        label
        variable_name
      }
      option {
        label
        value
      }
    }
  }
}
`;

const STUDY_LIST = gql`
query ListStudies($user_id: Int!) {
  dia_app_user_study(where: {archived: {_eq: false}, user_id: {_eq: $user_id}, user: {archived: {_eq: false}, user_studies: {archived: {_eq: false}}}, study: {archived: {_eq: false}}}) {
    study {
      id
      name
    }
  }
}
`;

const STUDY_LIST_ADMIN = gql`
  query ListStudies {
    dia_app_user_study {
      study {
        id
        name
      }
    }
  }
`;

const SITE_LIST = gql`
query GET_SITE_LIST_BY_STUDY_ID($study_id: Int!, $user_id: Int!) {
  dia_app_site(where: {study_sites: {archived: {_eq: false}}, user_study_sites: {user_id: {_eq: $user_id}, study_id: {_eq: $study_id}, study: {archived: {_eq: false}, user_studies: {archived: {_eq: false}, study: {archived: {_eq: false}}}}, user: {archived: {_eq: false}}, archived: {_eq: false}}, archived: {_eq: false}}) {
    id
    name
  }
}
`;

const SITE_LIST_ADMIN = gql`
query GET_SITE_LIST_BY_STUDY_ID($study_id: Int!) {
       dia_app_site(where: {study_sites: {study_id: {_eq: $study_id}}}) {
          id
          name
    }
}
`;

const ASSESSMENT_LIST = gql`
  query GET_ASSESSMENT_LIST_BY_STUDY_ID($study_id: Int!, $user_id: Int!) {
    dia_app_interval_assessment(
      where: {
        study_id: { _eq: $study_id }
        study: { user_studies: { user: { id: { _eq: $user_id } } } }
      }
      distinct_on: assessment_id
    ) {
      assessment {
        id
        name
      }
    }
  }
`;

const ASSESSMENT_LIST_ADMIN = gql`
  query GET_ASSESSMENT_LIST_BY_STUDY_ID($study_id: Int!) {
    dia_app_interval_assessment(
      where: { study_id: { _eq: $study_id } }
      distinct_on: assessment_id
    ) {
      assessment {
        id
        name
      }
    }
  }
`;

export default {
  name: "AssessmentsView",
  mixins: [hasuraService],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      selected_site: null,
      selected_subject: "",
      selected_visit: null,
      sas_token_path: null,
      selected_site: null,
      site_list: [],
      dialog: false,
      pdf_loading: false,
      ready: false,
      loading: true,
      headers: [],
      base_headers: [
        { text: "Record ID", value: "id" },
        { text: "Study Name", value: "study_name" },
        { text: "Study Identifier", value: "study_identifier" },
        { text: "Site Name", value: "site_name" },
        { text: "Site Identifier", value: "site_identifier" },
        { text: "Interval Name", value: "interval_name" },
        { text: "Assessment Name", value: "assessment_name" },
        { text: "Assessment Key", value: "assessment_key" },
        { text: "Subject", value: "subject_full_id" },
        { text: "Record Status", value: "record_status" },
        // { text: "Archived", value: 'archived' },
        // push records here
        {
          text: "Out of Assessment Window Completion",
          value: "out_of_assessment_window_completion",
        },
        // { text: "Out of Interval Window Completion", value: 'out_of_interval_window_completion' },
        { text: "Created At", value: "created_at" },
        { text: "Submitted At", value: "submitted_at" },
      ],
      assessment_id: null,
      study_id: null,
      study_list: [],
      assessment_list: [],
      data: [],
    };
  },
  methods: {
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    is_media(value) {
      if (value == null) return false;
      // if value is string
      if (typeof value === "string" || value instanceof String) {
        return value.slice(0, 5) == "MEDIA";
      }
      return false;
    },
    async get_srs_tokens(image_paths) {
      // remove "MEDIA: " from string

      const query = gql`
        query ImageDownload($image_name: String = "100.jpg") {
          action_image_download(image_name: $image_name) {
            full_url
            reason
            result
            sas_url
          }
        }
      `;

      image_paths = image_paths.slice(7);
      image_paths = image_paths.split(", ");
      console.log(image_paths);
      if (image_paths.length == 1) {
        // simple case
        // this.sas_token_path = "tbd/" + image_paths[0];

        let image_name = image_paths[0] + ".jpg";

        try {
          const { data } = await this.$apollo.query({
            query: query,
            variables: {
              image_name,
            },
          });

          console.log(data);
          this.sas_token_path = data.action_image_download.full_url;
          this.dialog = true;
        } catch (e) {
          console.log(e);
          alert("Error Getting Image");
          return;
        }
      } else {
        let data = {
          input: {
            image_name: image_paths[0] + ".jpg",
          },
        };

        let r = null;

        try {
          r = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          console.log(r);
        } catch (e) {
          console.log(e);
          alert("Error Getting Image");
          return;
        }

        if (r === null) {
          alert("Error Getting Image");
          console.log("result from server is null, or the fetch failed");
          return;
        }
        let j = await r.json();

        console.log(j);

        this.sas_token_path = j.full_url;
        this.dialog = true;
      }
    },
    async export_to_pdf() {
      // send data + header to server
      // server will generate pdf
      // server will return blob
      // client will save blob as pdf via a tag + click
      this.pdf_loading = true;

      let url =
        "https://com-sdcclinical-eyecup-prod.azurewebsites.net/api/pdf_data_table";
      // let url = "http://localhost:7071/api/pdf_data_table"

      let data = {
        input: {
          headers: this.headers,
          data: this.filtered_data.map((d) => {
            // convert null to ""
            Object.keys(d).forEach((key) => {
              if (d[key] == null) {
                d[key] = "";
              }
            });

            return {
              ...d,
              submitted_at: this.date_fmt(d.submitted_at),
              created_at: this.date_fmt(d.created_at),
              updated_at: this.date_fmt(d.updated_at),
            };
          }),
        },
      };

      let r = null;

      try {
        r = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      } catch (e) {
        console.log(e);
        this.pdf_loading = false;
        alert("Error generating PDF");
        return;
      }

      if (r === null) {
        this.pdf_loading = false;
        alert("Error generating PDF");
        console.log("result from server is null, or the fetch failed");
        return;
      }

      let blob = await r.blob();
      console.log(blob);

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "SDC Capture Report.pdf";
      link.click();

      // clean up link
      link.remove();
      this.pdf_loading = false;
    },
    date_fmt(dt) {
      if (dt == null) return "";
      if (dt == "") return "";
      let date = new Date(dt);
      let newDate = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/New_York",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZoneName: "short",
      }).format(date);
      return newDate;
    },
    async update_site_list() {
      this.selected_site = null;
      this.assessment_id = null;
      this.selected_subject = "";
      this.selected_visit = null;
      this.data = [];
      console.log("Grabbing site list")
      if (this.$store.state.user.admin) {
        const { data } = await this.$apollo.query({
        query: SITE_LIST_ADMIN,
        client: this.uat_prod,
        variables: {
          study_id: this.study_id,
        },
        });
        this.site_list = data.dia_app_site.map(s => ({ text: s.name, value: s.id }));
      }
      else {
        const { data } = await this.$apollo.query({
        query: SITE_LIST,
        client: this.uat_prod,
        variables: {
          study_id: this.study_id,
          user_id: this.$store.state.user.id,
        },
        });
        console.log("site list", data)
        this.site_list = data.dia_app_site.map(s => ({ text: s.name, value: s.id }));
      }
      
      
      if (this.site_list.length == 0) {
        alert("No sites found for this study");
      }
    },
    async load() {
      // This stops a race condition where the jwt is not loaded yet
      await this.$store.state.jwt_loading;
      this.study_list = [];
      this.study_id = null;
      this.selected_site = null;
      this.assessment_id = null;
      var token = null;

      //TODO - handle prod token globally instead of this
      token = localStorage.getItem("token");

      if (token) {
        let decoded = this.parseJwt(token);
        this.$store.state.user.id = +decoded.sub;
      }
      this.study_list = [];
      this.assessment_list = [];
      this.data = [];
      console.log("load", this.$store.state.user.id);

      let data = null;

      if (this.$store.state.user.admin) {
        let r = await this.$apollo.query({
          query: STUDY_LIST_ADMIN,
        });
        data = r.data;
      } else {
        let r = await this.$apollo.query({
          query: STUDY_LIST,
          variables: {
            user_id: this.$store.state.user.id,
          },
        });
        data = r.data;
      }

      if (data.dia_app_user_study == null) {
        alert("No studies found for this user");
        return;
      }
      this.study_list = data.dia_app_user_study.map((s) => ({
        text: s.study.name,
        value: s.study.id,
      }));
    },
    async update_assessment_list() {
      console.log("Grabbing assessment list");
      let data = null;

      if (this.$store.state.user.admin) {
        let r = await this.$apollo.query({
          query: ASSESSMENT_LIST_ADMIN,
          variables: {
            study_id: this.study_id,
          },
        });
        data = r.data;
      } else {
        let r = await this.$apollo.query({
          query: ASSESSMENT_LIST,
          variables: {
            study_id: this.study_id,
            user_id: this.$store.state.user.id,
          },
        });
        data = r.data;
      }

      this.assessment_list = data.dia_app_interval_assessment.map((ia) => ({
        text: ia.assessment.name,
        value: ia.assessment.id,
      }));
      if (this.assessment_list.length == 0) {
        alert("No assessments found for this study");
      }
    },

    async main_run() {
      this.ready = true;
      this.loading = true;

      let data = null;

      if (this.$store.state.user.admin) {
        let r = await this.$apollo.query({
          query: MAIN_QUERY_ADMIN,
          variables: {
            assessment_id: this.assessment_id, // 14
            study_id: this.study_id, // 24
            site_id: this.selected_site
          },
        });
        data = r.data;
      } else {
        let r = await this.$apollo.query({
          query: MAIN_QUERY,
          variables: {
            assessment_id: this.assessment_id, // 14
            study_id: this.study_id, // 24
            user_id: this.$store.state.user.id,
            site_id: this.selected_site
          },
        });
        data = r.data;
      }

      this.result = data.dia_app_record;
      let found_headers = [];
      this.headers = [...this.base_headers];
      let d = this.result.map((r) => {
        let response_data = {};
        r.responses.forEach((response) => {
          console.log(response)
          if (!found_headers.includes(response.question.variable_name)) {
            found_headers.push(response.question.variable_name);
            this.headers.splice(9 + found_headers.length, 0, {
              text: response.question.label,
              value: response.question.variable_name,
            });
          }

          if (response.side === null) {
            if (response_data[response.question.variable_name]) {
              response_data[response.question.variable_name] +=
                " | " + response.value;
            } else {
              response_data[response.question.variable_name] = response.value;
            }
          } else {
            if (response_data[response.question.variable_name]) {
              response_data[response.question.variable_name] +=
                " | " + response.side + ": " + response.value;
            } else {
              response_data[response.question.variable_name] =
                response.side + ": " + response.value;
            }
          }
          if (response.value.endsWith(".jpg") || response.value.endsWith(".mp4")) {
            let media = response.media.map((m) => m.id);
            if (media.length == 0) {
              response_data[response.question.variable_name] = "MISSING";
            } else {
              response_data[response.question.variable_name] =
                "MEDIA: " + media.join(", ");
            }
          }
        });

        let d = {
          id: r.id,
          study_name: r.study.name,
          study_identifier: r.study.study_identifier, // NOT WORKING?
          assessment_name: r.interval_assessment.assessment.name,
          assessment_key: r.interval_assessment.assessment.assessment_key, // NOT WORKING?
          interval_name: r.interval_assessment.interval.name,
          site_name: r.site.name,
          site_identifier: r.site.identifier, // NOT WORKING?
          record_status: r.status, // where?
          archived: r.archived,
          out_of_assessment_window_completion:
            r.out_of_assessment_window_completion,
          out_of_interval_window_completion:
            r.out_of_interval_window_completion,
          created_at: r.created_at,
          updated_at: r.updated_at,
          submitted_at: r.submitted_at,
          subject_full_id: r.subject.subject_full_id,
        };
        return { ...d, ...response_data };
      });

      this.data = d;

      this.loading = false;
    },
  },
  computed: {
    sites() {
      if (this.data == null) return [];
      return ["All"].concat(this.data.map((r) => r.site_name));
    },
    subjects() {
      if (this.data == null) return [];
      return ["All"].concat(this.data.map((r) => r.subject_full_id));
    },
    visits() {
      if (this.data == null) return [];
      return ["All"].concat(this.data.map((r) => r.interval_name));
    },
    filtered_data() {
      let d = this.data;
      // if (this.selected_site && this.selected_site != "All") {
      //   d = d.filter((r) => r.site_name == this.selected_site);
      // }
      if (this.selected_subject && this.selected_subject != "All") {
        d = d.filter((r) => r.subject_full_id == this.selected_subject);
      }
      if (this.selected_visit && this.selected_visit != "All") {
        d = d.filter((r) => r.interval_name == this.selected_visit);
      }
      return d;
    },
    jsonData() {
      // [
      // { name: 'Joe', surname: 'Roe' },
      // { name: 'John', surname: 'Doe' }
      // ]

      return this.data.map((d) => {
        let f = {};
        this.headers.forEach((header) => {
          f[header.value] = d[header.value] || "";
        });
        return f;
      });
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>
