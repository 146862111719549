<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="290">
      <v-card v-if="site">
        <v-card-title class="text-h5">
          <span v-if="this.archive">Unarchive</span>
          <span v-else>Archive</span>
          &nbsp;site?
        </v-card-title>
        <v-card-text>{{ site.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Cancel </v-btn>
          <v-btn
            :loading="isLoading"
            color="error"
            @click="archiveSite(site.id)"
          >
            <span v-if="this.archive">Unarchive</span>
            <span v-else>Archive</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "site", "archive"],
  mixins: [hasuraService],
  data: () => ({
    isLoading: false,
    userId: null,
    studyName: "",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async archiveSite(siteId) {
      this.isLoading = true;
      const result = await this.archiveSiteRequest(siteId, !this.archive);
      if (result) {
        this.$emit("callback", siteId);
        this.show = false;
      }

      this.isLoading = false;
    },
  },
};
</script>
