<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Study</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0" v-if="loaded">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="select"
                  :loading="isLoading"
                  :items="items"
                  :search-input.sync="search"
                  item-text="complete_name"
                  item-value="id"
                  class="mt-6"
                  hide-no-data
                  label="Start typing to search for a site"
                  solo
                  :error-messages="attachStudyError"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="show = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="addStudy"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["value", "user"],
  mixins: [hasuraService],
  data: () => ({
    loaded: false,
    isLoading: false,
    study: null,
    search: "",
    select: null,
    items: [],
    studies: [],
    attachStudyError: "",
    environment: "uat",
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async show(newVal) {
      if (newVal) {
        this.studies = await this.listStudies(
          this.$store.state.user.id,
          this.$store.state.user.admin
        );
        // shallow copy of studies
        let items = JSON.parse(JSON.stringify(this.studies));
        items.forEach((study) => {
          study.complete_name = `${study.id} - ${study.name}`;
        });
        this.items = items;
      }
    },
    async user(newVal) {
      if (newVal !== null) {
        /// todo: implement
      }
    },
    search(val) {
      // eslint-disable-next-line no-unused-expressions
      val && this.searchStudies(val);
      this.attachStudyError = "";
      this.isLoading = false;
    },
    select(val) {
      const pickedStudy = this.items.find((site) => site.id === val);
      if (pickedStudy) {
        this.study = pickedStudy;
      }
    },
  },
  methods: {
    async searchStudies() {
      // filter stored sites by name
      const studies = this.studies.filter((study) =>
        study.name.toLowerCase().includes(this.search.toLowerCase())
      );
      this.items = studies;
    },
    async addStudy() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      if (!this.study || !this.user) {
        this.isLoading = false;
        return false;
      }

      let userStudyData = null;
      try {
        userStudyData = await this.attachStudyToUser(
          this.user.id,
          this.study.id
        );
      } catch (error) {
        console.error("Error attaching study to user", error);
        this.isLoading = false;
      }

      if (userStudyData) {
        this.$emit("callback", userStudyData);
        this.show = false;
      } else {
        this.attachStudyError =
          "There was an error attaching the study to the user";
        this.isLoading = false;
        return false;
      }
      return true;
    },
    async fetchStudies() {
      this.environment = localStorage.getItem("environment") || "uat";
      this.studies = await this.listStudies(
        this.environment,
        this.$store.state.user.id,
        this.$store.state.user.admin
      );
      this.items = this.studies;
      this.loaded = true;
    },
  },
  mounted() {
    this.fetchStudies();
  },
};
</script>
