<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">User Studies</h1>
        <p v-if="user != null">{{ user.name }}</p>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived User Studies"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>

          <v-btn color="primary" @click="addStudy">Assign Study</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="filtered_data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.study.id }}</td>
          <td>{{ item.study.name }}</td>
          <td>{{ item.study.description }}</td>
          <td>{{ item.study.sponsor }}</td>
          <td>
            <v-btn
              class="mr-4"
              icon
              small
              @click="manageUserStudySites(item.study.id)"
              :disabled="toggle_archive"
            >
              <v-icon title="User Study Sites">
                mdi-office-building-marker
              </v-icon>
              <v-badge
                v-if="item.study.user_study_sites.length > 0"
                class="text-overline badge-size-small"
                color="secondary"
                :content="item.study.user_study_sites.length"
                offset-y="-2"
              />
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-1"
              icon
              small
              @click="archiveUserStudy(item.study)"
            >
              <v-icon dark v-if="!toggle_archive"> mdi-delete-outline </v-icon>
              <v-icon dark v-else> mdi-delete-restore </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <add-study-form
      v-model="showAddStudyForm"
      v-bind:user="user"
    ></add-study-form>

    <v-dialog v-model="showArchiveDialog" persistent max-width="400">
      <archive-form
        v-if="showArchiveDialog"
        :itemId="archive_id"
        :description="archive_description"
        :archive="toggle_archive"
        :archive_function="archiveUserStudyFunc"
        tableName="dia_app_user"
        prettyName="User Study"
        @close="showArchiveDialog = false"
      ></archive-form>
    </v-dialog>

    <manage-user-study-sites-form
      v-model="showManageUserStudySitesForm"
      v-bind:studyId="formStudyId"
      v-bind:user="user"
    ></manage-user-study-sites-form>
  </v-container>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import AddStudyForm from "@/components/users/AddStudyForm.vue";
import ArchiveForm from "@/components/Archive.vue";
import ManageUserStudySitesForm from "@/components/users/ManageUserStudySitesForm.vue";
import gql from "graphql-tag";

const baseUserStudies = `
dia_app_user_study(where: {user_id: {_eq: $user_id}}) {
    study_id
    user_id
    archived

    study {
      id
      name
      description
      sponsor
      user_study_sites(where: {user_id: {_eq: $user_id}}) {
        site_id
      }
    }
  }
`;

const GET_USER_STUDIES = gql`
  query GetUserStudies($user_id: Int!) {
    ${baseUserStudies}
  }
`;

const SUBSCRIPTION_USER_STUDIES = gql`
  subscription OnUserStudies($user_id: Int!) {
    ${baseUserStudies}
  }
`;

export default {
  name: "UserStudiesView",
  mixins: [hasuraService],
  components: {
    "add-study-form": AddStudyForm,
    "archive-form": ArchiveForm,
    "manage-user-study-sites-form": ManageUserStudySitesForm,
  },

  data() {
    return {
      loading: true,
      toggle_archive: false,
      showArchiveDialog: false,
      archive_id: null,
      archive_description: "",
      archiveUserStudyFunc: null,

      headers: [
        { text: "ID", value: "id", width: "70px" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Sponsor", value: "sponsor", width: "100px" },
        { text: "Sites", value: "sites", width: "100px" },
        { text: "Actions", value: "actions", width: "120px" },
      ],
      dia_app_user_study: [],
      user: null,

      showAddStudyForm: false,
      showArchiveStudyDialog: false,
      showManageUserStudySitesForm: false,
      formStudyId: null,
      formStudy: null,
    };
  },
  computed: {
    filtered_data() {
      return this.dia_app_user_study.filter((study) => {
        return study.archived === this.toggle_archive;
      });
    },
  },
  methods: {
    async addStudy() {
      this.showAddStudyForm = true;
    },
    async archiveUserStudy(study) {
      this.formStudyId = study.id;
      // because user study doesn't have an id
      // we need to create a custom function to archive
      this.archiveUserStudyFunc = async (_archive) => {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ArchiveUserStudy(
              $user_id: Int!
              $study_id: Int!
              $archive: Boolean!
            ) {
              update_dia_app_user_study(
                where: {
                  study_id: { _eq: $study_id }
                  user_id: { _eq: $user_id }
                }
                _set: { archived: $archive, archived_at: "now()" }
              ) {
                affected_rows
              }
            }
          `,
          variables: {
            study_id: study.id,
            user_id: this.$route.params.id,
            archive: _archive,
          },
        });
      };
      this.archive_description = study.name;
      this.showArchiveDialog = true;
    },
    async manageUserStudySites(studyId) {
      console.log("manageUserStudySites", studyId);
      this.formStudyId = studyId;
      this.showManageUserStudySitesForm = true;
    },
  },

  apollo: {
    dia_app_user_study: {
      query: GET_USER_STUDIES,
      variables() {
        return {
          user_id: this.$route.params.id,
        };
      },
      subscribeToMore: {
        document: SUBSCRIPTION_USER_STUDIES,
        variables() {
          return {
            user_id: this.$route.params.id,
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          // With the new data, there's a chance there's a new user study
          // so we need to update the user study list, and we can't use the cache obviously
          this.getUserByIdRequest(this.$route.params.id, false).then((user) => {
            this.user = user;
          });
          return subscriptionData.data;
        },
      },
    },
  },

  async mounted() {
    // get user by id
    this.user = await this.getUserByIdRequest(this.$route.params.id);
    this.loading = false;
  },
};
</script>

<style>
.badge-size-small .v-badge__wrapper .v-badge__badge {
  font-size: 10px;
  height: 15px;
  min-width: 15px !important;
  padding: 3px 2px;
}
</style>
