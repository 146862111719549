<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">Visit Assessments</h1>
        <v-breadcrumbs :items="breadcrumbs" class="pl-0 pt-2 pb-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col class="text-right" align-self="end">
        <v-spacer></v-spacer>
        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3 mt-1"
            hide-details
            label="Archived Visits"
            v-model="toggle_archive"
            v-if="$store.state.user.admin"
          ></v-switch>
          <v-btn
            color="primary"
            @click="addVisitAssessment"
            :disabled="$store.state.app_locked"
            >Add Assessment</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
    >
      <template v-slot:body="props">
        <draggable :list="props.items" tag="tbody" @end="saveSequense">
          <tr
            v-for="(item, index) in props.items"
            :key="index"
            class="drag-row"
          >
            <td>{{ item.id }}</td>
            <td>
              <!-- <v-btn link plain text small class="no-capitalize" :to="{ name: 'Studies' }">{{ item.assessment.name
              }}</v-btn> -->
              {{ item.assessment.name }}
            </td>
            <td>{{ item.positive_slack_window }}</td>
            <td>{{ item.negative_slack_window }}</td>
            <td>{{ item.time_delay }}</td>
            <td>{{ item.time_unit }}</td>
            <td>{{ item.sequence }}</td>
            <td>
              <v-btn
                title="Edit"
                class="mx-1"
                icon
                small
                @click="editVisitAssessment(item)"
                :disabled="$store.state.app_locked"
              >
                <v-icon dark> mdi-pencil-outline </v-icon>
              </v-btn>
              <!-- <v-btn
                title="Duplicate"
                class="mx-1"
                icon
                small
                @click="duplicateVisitAssessment(item)"
                :disabled="$store.state.app_locked"
              >
                <v-icon dark> mdi-content-duplicate </v-icon>
              </v-btn> -->
              <v-btn
                title="Archive"
                class="mx-1"
                icon
                small
                @click="archiveVisitAssessment(item.id)"
                :disabled="$store.state.app_locked"
              >
                <v-icon v-if="toggle_archive" dark> mdi-delete-restore </v-icon>
                <v-icon v-else dark> mdi-delete-outline </v-icon>
              </v-btn>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <edit-visit-assessment-form
      v-bind:visitAssessment="visitAssessment"
      v-bind:study_id="study.id"
      v-bind:visit_id="visit.id"
      v-model="showEditAssessmentForm"
      v-if="showEditAssessmentForm"
      @callback_add="addVisitAssessmentCallback"
      @callback_edit="editVisitAssessmentCallback"
    ></edit-visit-assessment-form>
    <archive-visit-assessment-dialog
      v-if="visit && study"
      v-bind:visitAssessmentId="visitAssessmentId"
      v-bind:archive="toggle_archive"
      v-model="showArchiveAssessmentDialog"
      @callback="archiveVisitAssessmentCallback"
    ></archive-visit-assessment-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import draggable from "vuedraggable";
import hasuraService from "@/services/hasura.service.js";
// import AddVisitAssessmentForm from "@/components/studies/AddVisitAssessmentForm.vue";
import EditVisitAssessmentForm from "@/components/studies/EditVisitAssessmentForm.vue";
import ArchiveVisitAssessmentDialog from "@/components/studies/ArchiveVisitAssessmentDialog.vue";

export default {
  name: "VisitAssessmentsView",
  mixins: [hasuraService],
  components: {
    // "add-visit-assessment-form": AddVisitAssessmentForm,
    "archive-visit-assessment-dialog": ArchiveVisitAssessmentDialog,
    "edit-visit-assessment-form": EditVisitAssessmentForm,
    draggable,
  },

  data() {
    return {
      loading: true,
      toggle_archive: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Pos. Slack", value: "positive_slack_window" },
        { text: "Neg. Slack", value: "negative_slack_window" },
        { text: "Time Delay", value: "time_delay" },
        { text: "Time Unit", value: "time_unit" },
        { text: "Sequence", value: "sequence" },
        { text: "Actions", value: "actions", width: "140px" },
      ],
      study: null,
      visit: null,
      assessments: null,

      breadcrumbs: [],
      data: [],

      showAddVisitAssessmentForm: false,
      showEditAssessmentForm: false,
      showArchiveAssessmentDialog: false,
      visitAssessmentId: null,
      visitAssessment: null,
    };
  },
  watch: {
    toggle_archive() {
      this.loading = true;
      this.data = [];
      this.listAssessmentsByVisitsId(this.$route.params.visitId).then(
        (visitAssessments) => {
          this.data = visitAssessments;
          this.loading = false;
        }
      );
    },
  },
  methods: {
    async listAssessmentsByVisitsId(visitId) {
      return await this.listAssessmentsByVisitsIds(
        [this.$route.params.visitId],
        this.toggle_archive,
        false
      );
    },
    async duplicateVisitAssessment(item) {
      const newItem = { ...item };
      newItem.id = null;
      newItem.sequence = this.data.length + 1;
      // pushes before done to avoid re-rendering
      this.data.push(newItem);

      console.log("Duplicating Item... ", item);
      const addedVisitAssessment = await this.addVisitAssessmentRequest({
        assessmentId: newItem.assessment_id,
        psw: newItem.positive_slack_window,
        nsw: newItem.negative_slack_window,
        sequence: newItem.sequence,
        studyId: newItem.study_id,
        intervalId: newItem.interval_id,
        timeDelay: newItem.time_delay,
        timeUnit: newItem.time_unit,
      });

      console.log(addedVisitAssessment);
      this.data[this.data.length - 1].id = addedVisitAssessment.id;
    },
    saveSequense(action) {
      const { oldIndex, newIndex } = action;
      let local_data = JSON.parse(JSON.stringify(this.data));

      const item = local_data.splice(oldIndex, 1)[0];
      local_data.splice(newIndex, 0, item);
      local_data.forEach((element, index) => {
        element.sequence = index + 1;

        this.updateStudyVisitAssessmentSequense({
          id: element.id,
          sequence: element.sequence,
        });
      });
      this.data = local_data;
    },
    async setBreadcrumbs() {
      const { visitId } = this.$route.params;
      const study = this.$store.getters.study(this.$route.params.id);
      const visit = this.$store.getters.visit(visitId);

      if (study == null) {
        this.study = await this.fetchStudy(this.$route.params.id);
      } else {
        this.study = study;
      }

      if (visit == null) {
        this.visit = await this.fetchVisitData(visitId);
      } else {
        this.visit = visit;
      }

      if (this.study && this.study.name && this.visit && this.visit.name) {
        this.breadcrumbs = [
          {
            text: "Studies",
            disabled: false,
            to: { name: "Studies" },
            exact: true,
          },
          {
            text: this.study.name,
            disabled: false,
            to: { name: "Visits", params: { id: this.study.id } },
            exact: true,
          },
          {
            text: this.visit.name,
            disabled: true,
          },
        ];
      }
    },
    async fetchStudy(id) {
      return this.fetchStudyData(id);
    },
    async fetchVisit(visitId) {
      return this.fetchVisitData(visitId);
    },
    async addVisitAssessment() {
      this.visitAssessment = null;
      this.showEditAssessmentForm = true;
    },
    async addVisitAssessmentCallback(addedAssessment) {
      this.data.push(addedAssessment);
    },
    async archiveVisitAssessment(id) {
      this.visitAssessmentId = id;
      this.showArchiveAssessmentDialog = true;
    },
    async archiveVisitAssessmentCallback() {
      this.data = this.data.filter(
        (item) => item.id !== this.visitAssessmentId
      );
    },
    async editVisitAssessment(visitAssessment) {
      this.visitAssessment = visitAssessment;
      this.showEditAssessmentForm = true;
    },
    async editVisitAssessmentCallback(updatedVisitAssessmentData) {
      const index = this.data.findIndex(
        (item) => item.id === updatedVisitAssessmentData.id
      );
      this.data[index].time_delay = updatedVisitAssessmentData.time_delay;
      this.data[index].time_unit = updatedVisitAssessmentData.time_unit;
      this.data[index].positive_slack_window =
        updatedVisitAssessmentData.positive_slack_window;
      this.data[index].negative_slack_window =
        updatedVisitAssessmentData.negative_slack_window;

      this.data[index].clinician_reported =
        updatedVisitAssessmentData.clinician_reported;
      this.data[index].allow_out_of_window_completion =
        updatedVisitAssessmentData.allow_out_of_window_completion;
      this.data[index].schedule_start_assessment =
        updatedVisitAssessmentData.schedule_start_assessment;
      this.data[index].interval_assessment_anchor_for_slack_window =
        updatedVisitAssessmentData.interval_assessment_anchor_for_slack_window;
      this.data[index].required = updatedVisitAssessmentData.required;
      this.data[index].sequence = updatedVisitAssessmentData.sequence;
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    const visitAssessments = await this.listAssessmentsByVisitsId(
      this.$route.params.visitId
    );
    this.data = JSON.parse(JSON.stringify(visitAssessments));

    this.loading = false;
  },
};
</script>

<style>
.v-btn.no-capitalize {
  text-transform: none !important;
}

.reorder-btn {
  cursor: grab;
}

.reorder-btn.grabbing {
  cursor: grabbing;
}

.drag-row {
  cursor: move;
}
</style>
