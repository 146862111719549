<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="290">
      <v-card v-if="studySite !== null">
        <v-card-title class="text-h5">
          <span v-if="!archive">Archive</span>
          <span v-else>Unarchive</span>
          <!-- Yes the &nbsp; is needed, i hate html -->
          &nbsp;study site?
        </v-card-title>
        <v-card-text>{{ studySite.site.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Cancel </v-btn>
          <v-btn color="error" @click="detachSite()">
            <span v-if="!archive">Archive</span>
            <span v-else>Unarchive</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  props: ["studySite", "value", "archive"],
  mixins: [hasuraService],
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async detachSite() {
      const result = await this.archiveSiteFromStudy(
        this.studySite.site_id,
        this.studySite.study_id,
        !this.archive
      );
      if (result) {
        this.show = false;
        this.$emit("callback", this.studySite.site_id);
      }
      return result;
    },
  },
};
</script>
