<template>
  <v-container>
    <v-row class="my-2">
      <v-col>
        <h1 class="display-6 font-weight-regular">Studies</h1>
      </v-col>
      <v-col cols="5" class="text-right" justify="end" align-self="end">
        <v-spacer></v-spacer>

        <v-row justify="end" align-self="end">
          <v-switch
            class="mr-3"
            hide-details
            label="Archived Studies"
            v-model="archieved_studies"
            v-if="$store.state.user.admin"
          ></v-switch>

          <v-btn
            class="mr-3"
            color="primary"
            @click="newStudy"
            :disabled="$store.state.app_locked"
          >
            New Study
          </v-btn>
        </v-row>
        <!-- toggle archived -->
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.study_sites="{ item }">
        <v-btn
          class="mr-4"
          icon
          small
          :to="{ name: 'StudySites', params: { id: item.id } }"
        >
          <v-icon dark title="Sites"> mdi-office-building-marker </v-icon>
          <v-badge
            v-if="item.study_sites.length > 0"
            class="text-overline badge-size-small"
            color="secondary"
            :content="item.study_sites.length"
            offset-y="-2"
          />
        </v-btn>
      </template>
      <template v-slot:item.visits="{ item }">
        <v-btn
          class="mr-4"
          icon
          small
          :to="{ name: 'Visits', params: { id: item.id } }"
        >
          <v-icon dark title="Visits">
            mdi-clipboard-text-clock-outline
          </v-icon>
          <v-badge
            v-if="item.intervals_aggregate.aggregate.count > 0"
            class="text-overline badge-size-small"
            color="secondary"
            :content="item.intervals_aggregate.aggregate.count"
            offset-y="-2"
          />
        </v-btn>
      </template>
      <template v-slot:item.schedule="{ item }">
        <v-btn
          icon
          small
          :to="{ name: 'VisitsSchedule', params: { id: item.id } }"
        >
          <v-icon dark title="Schedule"> mdi-calendar-month </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-1"
          icon
          small
          @click="editStudy(item.id)"
          :disabled="$store.state.app_locked"
        >
          <v-icon dark> mdi-pencil-outline </v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="archiveStudy(item.id, true)"
          :disabled="$store.state.app_locked"
          v-if="!item.archived"
          title="Archive Study"
        >
          <v-icon dark> mdi-delete-outline </v-icon>
        </v-btn>
        <v-btn
          class="mx-1"
          icon
          small
          @click="archiveStudy(item.id, false)"
          :disabled="$store.state.app_locked"
          v-if="item.archived"
          title="Unarchive Study"
        >
          <v-icon dark> mdi-delete-restore </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <study-form
      v-model="showStudyForm"
      v-bind:studyId="studyFormId"
      @callback="studyCallback"
    ></study-form>
    <archive-study-dialog
      v-model="showArchiveStudyDialog"
      v-bind:studyId="archiveStudyId"
      v-bind:setIsAchived="setIsAchived"
      @callback="archiveStudyCallback"
    ></archive-study-dialog>
  </v-container>
</template>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";
import ArchiveStudyDialog from "@/components/studies/ArchiveStudyDialog.vue";
import StudyForm from "@/components/studies/StudyForm.vue";

export default {
  name: "StudiesView",
  mixins: [hasuraService],
  components: {
    "archive-study-dialog": ArchiveStudyDialog,
    "study-form": StudyForm,
  },

  data() {
    return {
      loading: true,
      archieved_studies: false,
      headers: [
        { text: "ID", value: "id", width: "70px" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Sponsor", value: "sponsor" },
        { text: "Sites", value: "study_sites", width: "80px" },
        { text: "Visits", value: "visits", width: "90px" },
        { text: "Schedule", value: "schedule", width: "100px" },
        { text: "Actions", value: "actions", width: "120px" },
      ],
      data: [],
      studies: [],

      showArchiveStudyDialog: false,
      showStudyForm: false,
      studyFormId: null,
      archiveStudyId: null,
      setIsAchived: null,
    };
  },
  watch: {
    archieved_studies: {
      handler: function (val, oldVal) {
        this.listStudies(
          localStorage.getItem("environment") || "production",
          this.$store.state.user.id,
          this.$store.state.user.admin
        ).then((studies) => {
          if (this.archieved_studies) {
            this.data = studies.filter((study) => study.archived === true);
          } else {
            this.data = studies.filter((study) => study.archived === false);
          }
        });
      },
    },
  },
  methods: {
    newStudy() {
      this.showStudyForm = true;
    },
    editStudy(studyId) {
      this.studyFormId = studyId;
      this.showStudyForm = true;
    },
    studyCallback(studyData) {
      const index = this.data.findIndex(
        (element) => element.id === studyData.id
      );
      if (index === -1) {
        this.data.push(studyData);
      } else {
        this.data[index].name = studyData.name;
        this.data[index].description = studyData.description;
        this.data[index].sponsor = studyData.sponsor;
        this.data[index].edc = studyData.edc;
        this.data[index].external_id = studyData.external_id;
      }

      this.$store.commit("updateStudy", studyData);
    },
    archiveStudy(studyId, archive) {
      this.archiveStudyId = studyId;
      this.showArchiveStudyDialog = true;
      this.setIsAchived = archive;
    },
    archiveStudyCallback(studyId) {
      this.data = this.data.filter((element) => element.id !== studyId);
      this.$store.commit("removeStudyFromStore", studyId);
    },
    async reloadStudies() {
      this.loading = true;
      this.data = [];
      this.data = await this.listStudies(
        this.$store.state.user.id,
        this.$store.state.user.admin
      );
      this.$store.commit("mergeStudies", { studies: this.data, reload: true });
      this.loading = false;
    },
  },
  async mounted() {
    console.log("is admin: ", this.$store.state.user.admin);
    let studies = await this.listStudies(
      this.$store.state.user.id,
      this.$store.state.user.admin
    );
    // deep copy of studies, since apollo cache is immutable
    studies = JSON.parse(JSON.stringify(studies));
    this.studies = studies;
    this.data = studies.filter((study) => study.archived === false);

    // No idea what this is doing
    this.$store.commit("mergeStudies", { studies: this.data, reload: true });

    this.loading = false;
  },
};
</script>

<style>
.badge-size-small .v-badge__wrapper .v-badge__badge {
  font-size: 10px;
  height: 15px;
  min-width: 15px !important;
  padding: 3px 2px;
}
</style>
