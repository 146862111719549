import UsersView from '../views/UsersView.vue';
import UserStudiesView from '../views/UserStudiesView.vue';

export const userRoutes = [
    {
        path: '/users',
        name: 'Users',
        component: UsersView,
    },
    {
        path: '/users/:id/studies',
        name: 'UserStudies',
        component: UserStudiesView,
    },
];