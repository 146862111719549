import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';



const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#175EAD',
        onPrimary: '#FFFFFF',
        primaryContainer: '#D5E3FF',
        onPrimaryContainer: '#001B3C',
        secondary: '#555F71',
        onSecondary: '#FFFFFF',
        secondaryContainer: '#D9E3F8',
        onSecondaryContainer: '#121C2B',
        tertiary: '#6F5675',
        onTertiary: '#FFFFFF',
        tertiaryContainer: '#F8D8FE',
        onTertiaryContainer: '#28132F',
        error: '#BA1A1A',
        errorContainer: '#FFDAD6',
        onError: '#FFFFFF',
        onErrorContainer: '#410002',
        background: '#FDFBFF',
        onBackground: '#1A1C1E',
        surface: '#FDFBFF',
        onSurface: '#1A1C1E',
        surfaceVariant: '#E0E2EC',
        onSurfaceVariant: '#43474E',
        outline: '#74777F',
        onInverseSurface: '#F1F0F4',
        inverseSurface: '#2F3033',
        inversePrimary: '#A8C8FF',
        shadow: '#000000',
        surfaceTint: '#175EAD',
        outlineVariant: '#C4C6CF',
        scrim: '#000000',
      },
      dark: {
        primary: '#A8C8FF',
        onPrimary: '#003061',
        primaryContainer: '#004689',
        onPrimaryContainer: '#D5E3FF',
        secondary: '#BDC7DC',
        onSecondary: '#273141',
        secondaryContainer: '#3E4758',
        onSecondaryContainer: '#D9E3F8',
        tertiary: '#DBBCE1',
        onTertiary: '#3E2845',
        tertiaryContainer: '#563E5D',
        onTertiaryContainer: '#F8D8FE',
        error: '#FFB4AB',
        errorContainer: '#93000A',
        onError: '#690005',
        onErrorContainer: '#FFDAD6',
        background: '#1A1C1E',
        onBackground: '#E3E2E6',
        surface: '#1A1C1E',
        onSurface: '#E3E2E6',
        surfaceVariant: '#43474E',
        onSurfaceVariant: '#C4C6CF',
        outline: '#8E9099',
        onInverseSurface: '#1A1C1E',
        inverseSurface: '#E3E2E6',
        inversePrimary: '#175EAD',
        shadow: '#000000',
        surfaceTint: '#A8C8FF',
        outlineVariant: '#43474E',
        scrim: '#000000',
      },
    },
  },
});

Vue.use(Vuetify);

Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' });

export default vuetify;
